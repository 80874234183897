.schedulerTable {
    scrollbar-width: thin;
    scrollbar-color: #ffd0d0 #e4e4e4;
}

.syncScrollsTop {
    margin-bottom: 8px;
    scrollbar-width: thin;
    scrollbar-color: #ffd0d0 #e4e4e4;
}
.syncScrollsTable {
    padding-bottom: 8px;
    scrollbar-width: thin;
    scrollbar-color: #ffd0d0 #e4e4e4;
}

.schedulerTable.emptyschedulerTable {
    padding-bottom: 0;
}

.schedulerTable.emptyschedulerTable table {
    min-height: 260px;
}

.schedulerTable.emptyschedulerTable .ant-empty-description span {
    color: #c6c6c6 !important;
    font-weight: normal;
}

.schedulerTable .ant-table-tbody tr > td:first-of-type {
    min-width: 140px !important;
    max-width: 140px !important;
    display: table-cell;
    padding: 21px 20px !important;
    font-weight: bold;
}

.schedulerTable .ant-table-tbody tr > td:first-of-type .resourceOverbooking {
    font-weight: bold;
    color: red;
}

.schedulerTable .ant-table-tbody tr > td:first-of-type .emptyResource {
    font-weight: normal;
    opacity: .5;
}

.schedulerTable .ant-table-tbody tr > td:first-of-type .resourceOverbooking svg {
    position: absolute;
    background-color: #FFF;
    border: 1px solid;
    border-radius: 50%;
    padding: 2px;
    fill: red;
    left: 0px;
}

.schedulerTable .ant-table-tbody tr > td:nth-child(4n+1),
.schedulerTable .ant-table-thead tr:nth-of-type(2) > th:nth-child(4n+4),
.schedulerTable .ant-table-thead tr:nth-of-type(1) > th {
    border-right-color: #a1a1a1 !important;
}

.schedulerTable .ant-table-tbody > tr > td {
    border-top-color: #a1a1a1 !important;
}

.schedulerTable .ant-table-thead > tr:first-child > th:first-of-type,
.schedulerTable .ant-table-thead > tr:nth-of-type(2) > th {
    border-bottom-color: #a1a1a1 !important;
}

.schedulerTable .ant-table-thead tr:nth-of-type(2) > th {
    min-width: 65px;
}

.schedulerTable .ant-table-tbody .ant-table-cell.minute-cell {
    padding: 30px 16px !important;
}

.schedulerTable .ant-table-cell.minute-cell span,
.schedulerTable .ant-table-cell.minute-cell > div {
    position: absolute;
    left: -6px;
    top: 6px;
    font-weight: normal;
    color: #b3b3b3 !important;
    font-size: 10px;
    background-color: #FFF;
    height: 50px;
}

.schedulerTable .ant-table-cell.minute-cell > div.multiEvent {
    left: 0;
    top: unset;
}

.schedulerTable .ant-table-thead .ant-table-cell.minute-cell span {
    height: 14px;
}

.schedulerTable .ant-table-cell.minute-cell span.event-cell,
.schedulerTable .ant-table-cell.minute-cell .schedulerEvent {
    left: 0;
    top: unset;
    background-color: var(--primary-color);
    padding: 5px 4px;
    border-radius: 8px;
    transform: translateY(-50%);
    color: #FFF !important;
    z-index: 1;
    font-size: 12px;
    min-width: 200%;
    display: grid;
    place-content: center;
    border: 1px solid #FFF;
}

.schedulerTable .ant-table-cell.minute-cell .schedulerEvent {
    border: 2px solid #FFF;
    box-shadow: 2px 2px 3px #00000038;
}

.schedulerTable .ant-table-cell.minute-cell .schedulerEvent.overbooking {
    border: 2px solid #000 !important;
}

.schedulerTable .ant-table-cell.minute-cell .schedulerEvent.overbooking:first-child {
    border-bottom-width: 1px !important;
}

.schedulerTable .ant-table-cell.minute-cell > .schedulerEvent.overbooking:not(:first-child) {
    border-top-width: 1px !important;
}

.schedulerTable .ant-table-cell.minute-cell .schedulerEvent.pax {
    padding-right: 20px;
}

.schedulerTable .ant-table-cell.minute-cell .schedulerEvent > div {
    display: flex;
}

.schedulerTable .ant-table-cell.minute-cell .schedulerEvent .paxValues {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    justify-content: center;
    color: #FFF;
    background: #dd3e40;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    padding-inline: 4px;
    font-size: 11px;
}

.schedulerTable .ant-table-cell.minute-cell .schedulerEvent .paxValues.arrived {
    background: #457531;
}

.schedulerTable .ant-table-cell.minute-cell .schedulerEvent .paxValues.checking {
    background: #ae8b55;
}

/* .schedulerTable .ant-table-cell.minute-cell .schedulerEvent .eventStatus {
    position: absolute;
    top: -12px;
    left: 6px;
}

.schedulerTable .ant-table-cell.minute-cell .schedulerEvent .eventStatus a:not(:first-child) {
    margin-left: 3px;
} */

.schedulerTable .ant-table-cell.minute-cell .schedulerEvent .badge-event {
    position: absolute;
    left: 4px;
    width: 6px;
    min-width: 6px;
    height: 6px;
    background-color: var(--primary-color);
    border-radius: 100%;
    box-shadow: 0 0 0 1px #fff;
}

.schedulerTable .ant-table-cell.minute-cell .schedulerEvent .badge-event.badge-overbooking {
    top: 5px;
    background-color: #000;
}

.schedulerTable .ant-table-cell.minute-cell .schedulerEvent .badge-event.badge-price {
    top: 16px;
    background-color: #ffd666;
}

.schedulerTable .ant-table-cell.minute-cell .schedulerEvent .badge-event.badge-vip {
    top: 28px;
    background-color: #fff;
}

.schedulerTable .ant-table-cell.minute-cell .schedulerEvent .badge-event.badge-notes {
    bottom: 5px;
    background-color: #91caff;
}

.legendSchedulerTable {
    display: flex;
    gap: 12px;
    margin-bottom: 16px;
    margin-left: 10px;
    height: 16px;
    padding: 18px 0;
}

.legendSchedulerTable p {
    font-size: 10px;
    display: flex;
    align-items: center;
    gap: 3px;
}

.legendSchedulerTable span.badge-event {
    display: block;
    width: 10px;
    min-width: 10px;
    height: 10px;
    background-color: var(--primary-color);
    border-radius: 100%;
}

.legendSchedulerTable span.badge-event.badge-overbooking {
    background-color: #000;
}

.legendSchedulerTable span.badge-event.badge-price {
    background-color: #ffd666;
}

.legendSchedulerTable span.badge-event.badge-notes {
    background-color: #91caff;
}

.legendSchedulerTable span.badge-event.badge-vip {
    background-color: #fff;
    box-shadow: 0px 0px 0px 1px #0000002e;
}