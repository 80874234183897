:root {
  --background-global-color1: '#d53369';
  --background-global-color2: '#daae51';
}

.basic-layout {
  background: linear-gradient(90deg, '#d53369' 0%, '#daae51' 100%);
  background: linear-gradient(90deg, var(--background-global-color1) 0%, var(--background-global-color2) 100%);
}
:root{
  --primary-color: #ff4d4f;
  --primary-color-h: 359.3;
  --primary-color-s: 100%;
  --primary-color-l: 65.1%;
  --primary-color-hsl: var(--primary-color-h), var(--primary-color-s), var(--primary-color-l);
  --primary-color-dark: hsl(var(--primary-color-h), var(--primary-color-s), calc(var(--primary-color-l) + 8%));
  --primary-color-opacity-10: hsla(var(--primary-color-h), var(--primary-color-s), var(--primary-color-l), 0.10);
  --primary-color-opacity-15: hsla(var(--primary-color-h), var(--primary-color-s), var(--primary-color-l), 0.15);
  --primary-color-opacity-30: hsla(var(--primary-color-h), var(--primary-color-s), var(--primary-color-l), 0.30);
  --primary-color-opacity-35: hsla(var(--primary-color-h), var(--primary-color-s), var(--primary-color-l), 0.35);
}
:root{
  --white: #FFF;
  --black: #000;
  --dark-primary: #151f28;
  --dark-secondary: #2b333b;
  --dark-trigger: #292e33;
  --dark-selects-bg: #1c2833;
}
section.dark-view * {
  color: #fff;
}

section.dark-view.ant-layout .ant-layout-sider,
section.dark-view.ant-layout .ant-layout-header,
section.dark-view .ant-menu-dark {
    background: var(--dark-secondary);
}

section.dark-view.ant-layout .ant-layout-sider-trigger {
  background: var(--dark-trigger);
}

section.dark-view .ant-layout {
  background: var(--dark-primary);
}

section.dark-view .ant-table-wrapper .ant-table,
section.dark-view .ant-table-wrapper .ant-table th {
  background-color: var(--dark-secondary) !important;
}

section.dark-view .ant-table tr:nth-child(2n+1) {
  background-color: #373e45;
}

section.dark-view .ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover > td,
section.dark-view .ant-table-wrapper .ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background-color: #373e45;
}

section.dark-view .ant-table-wrapper .ant-table-thead > tr > th {
  color: #FFF;
}

section.dark-view .ant-table thead tr:first-child {
  background-color: transparent;
}

section.dark-view .ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
section.dark-view .ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr:not(:last-child) > th,
section.dark-view .ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th {
  border-inline-end-color: #4f555c;
  border-bottom: 1px solid #4f555c;
}

section.dark-view .ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td:last-child,
section.dark-view .ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr:not(:last-child) > th:last-child,
section.dark-view .ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th:last-child,
section.dark-view .ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td:last-child {
  border-inline-end-color: transparent;
}

section.dark-view .ant-table-wrapper .ant-table-summary {
  background-color: transparent;
}
section.dark-view .ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td {
  border-inline-end-color: #4f555c;
}

section.dark-view .ant-select-selector,
section.dark-view .ant-picker,
section.dark-view .ant-btn-default,
section.dark-view .ant-input,
section.dark-view .ant-pagination-item {
  background-color: var(--dark-selects-bg) !important;
  border-color: #68686899 !important;
}

section.dark-view .ant-input::-webkit-input-placeholder, section.dark-view .ant-picker-input input::-webkit-input-placeholder {
  color: #ffffff8f;
}

section.dark-view .ant-input::placeholder,
section.dark-view .ant-picker-input input::placeholder {
  color: #ffffff8f;
}

section.dark-view .ant-picker input,
section.dark-view .ant-form-item .ant-form-item-label > label {
  color: #FFF;
}

section.dark-view .ant-select-single.ant-select-open .ant-select-selection-item {
  color: #FFF;
}

.dark-mode .ant-select-dropdown {
  background-color: var(--dark-secondary);
  border: 1px solid #6f7479;
}

.dark-mode .ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #4f555c;
}

.dark-mode .ant-select-dropdown .ant-select-item-option-content,
.dark-mode .ant-select-dropdown .ant-select-item,
.dark-mode .ant-select-dropdown .ant-select-item-empty .ant-empty-description {
  color: #FFF;
}

.dark-mode .ant-picker-dropdown .ant-picker-panel-container {
  background-color: var(--dark-secondary);
}

.dark-mode .ant-picker-dropdown .ant-picker-header button > span,
.dark-mode .ant-picker-dropdown .ant-picker-header button,
.dark-mode .ant-picker-dropdown .ant-picker-cell .ant-picker-cell-inner {
  color: #FFF;
}

.dark-mode .ant-picker-dropdown .ant-picker-body thead tr th {
  background-color: #4f555c !important;
  color: #FFF;
}

.dark-mode .ant-picker-dropdown .ant-picker-body tbody tr:nth-child(2n+1) {
  background-color: #ddd8d80d;
}

.dark-mode .ant-collapse .ant-collapse-content {
  background-color: #ffffff0a;
}

.dark-mode .ant-collapse > .ant-collapse-item {
  border-bottom: none;
}

.dark-mode .ant-checkbox .ant-checkbox-inner {
  background-color: transparent;
}

.dark-mode .ant-table-cell .ant-tag.bloqued {
  background-color: #eee;
}

/* MODAL */

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content {
  background-color: #454a4f;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-modal-close svg {
  fill: #FFF;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-modal-body *,
.dark-mode .bookingModalRow .bookingInfo .ant-input[disabled],
.dark-mode .bookingModalRow .personalDataInfo .ant-input[disabled] {
  color: #FFF;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .bookingModalRow .bookingInfo .ant-input[disabled],
.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .bookingModalRow .personalDataInfo .ant-input[disabled] {
  background-color: transparent !important;
  opacity: .8;
  border-bottom: none;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-modal-body .ant-checkbox-disabled + span {
  color: rgba(225, 255, 255, 0.25);
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-modal-body .ant-btn-default:disabled {
  opacity: .5;
}

.dark-mode .bookingModalRow .bookingInfo,
.dark-mode .bookingModalRow .personalDataInfo,
.dark-mode .bookingModalRow .activitiesList .activityItemCol {
  background-color: #a6a6ca45;
  border: 1px solid #e6e6fa5c;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .bookingModalRow .bookingInfo .ant-input,
.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .bookingModalRow .personalDataInfo .ant-input {
  background-color: #a6a6ca45 !important;
  border-bottom: 1px solid #d0d0d0;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-divider {
  -webkit-border-before: none;
          border-block-start: none;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-divider.ant-divider-with-text::before,
.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-divider.ant-divider-with-text::after {
  opacity: .1;
}

.dark-mode .bookingModalRow .paymentInfo.paid {
  background: #f6ffed9c;
  border-color: #b7eb8f8f;
}

.dark-mode .bookingModalRow .paymentInfo p.paid span {
  color: #c1fda7 !important;
}

.dark-mode .bookingModalRow .paymentInfo.unpaid {
  background: #86625f8f;
  border-color: #99726e;
}

.dark-mode .bookingModalRow .paymentInfo p.unpaid span {
  color: #ff98a0 !important;
}

.dark-mode .bookingModalRow .paymentInfo.parcial {
  background: #776a4d94;
  border-color: #a6956f;
}

.dark-mode .bookingModalRow .paymentInfo p.parcial span {
  color: #ffc58f !important;
}

.dark-mode .bookingModalRow .paymentInfo.bloqued {
  background: #d7a68561;
  border-color: #d7a68582;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-select-selector,
.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-picker,
.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-btn-default:not(.main-btn, .iconCircle, .accept-btn),
.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-input,
.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-pagination-item {
  background-color: #3e4144 !important;
  border-color: #68686899 !important;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-select-single.ant-select-disabled,
.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-input-number-disabled,
.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-input-disabled {
  opacity: .4;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-btn-default span {
  color: #FFF;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-input::-webkit-input-placeholder, .dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-picker-input input::-webkit-input-placeholder {
  color: #ffffff8f;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-input::placeholder,
.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-picker-input input::placeholder {
  color: #ffffff8f;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .rbc-off-range-bg {
  opacity: .2;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .rbc-header + .rbc-header,
.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid #939393;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .rbc-month-view {
  border: 1px solid #939393;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .rbc-month-row + .rbc-month-row {
  border-top: 1px solid #939393;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .rbc-header {
  border-bottom: 1px solid #939393;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .rbc-today {
  background-color: #eaf6ff5e;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .calendarCol .calendarOverlay {
  background-color: var(--dark-secondary);
  opacity: .6;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .calendarCol .ant-form-item .ant-select-selection-item-content {
  color: #2d2f31 !important;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .activityForm {
  background-color: var(--dark-secondary);
  border: 1px solid #d8d8d826;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-input-number-group .ant-input-number-group-addon,
.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-input-number-disabled,
.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-input-number {
  background-color: #3e4144 !important;
  border-color: #68686899 !important;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-input-number .ant-input-number-handler-wrap {
  background: #3e4144;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-input-number .ant-input-number-handler-wrap .ant-input-number-handler {
  border: none;
  height: 50%;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-input-number .ant-input-number-handler-wrap .ant-input-number-handler:hover {
  height: 50%;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .resumeRateBox,
.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .finishActivity {
  background-color: var(--dark-secondary);
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .resumeFinishRateBox {
  /*background-color: var(--dark-secondary);*/
  background-color: #474e55;
  border: 1px solid #5d6064;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .resumeFinishRateBox .ant-btn-default {
  background-color: #545657 !important;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-steps .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: rgba(255, 255, 255, 0.21);
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ratesActivity > div > div {
  background-color: #474e55;
  border: 1px solid #5d6064;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .activityData,
.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .personalData,
.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .paymentData {
  background-color: #9090bd2e;
  border: 1px solid #ddddec2e;
  border-radius: 10px;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .priceData {
  background-color: #ceac8136;
  border: 1px solid #faebd77d;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .aditionalServices .ant-collapse {
  border: 1px solid #eaeaea69;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .aditionalServices .ant-collapse .ant-collapse-content {
  background-color: #535763;
  padding-top: 14px;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .stepsForm .ant-steps-item-finish + .ant-steps-item-active .ant-steps-icon svg {
  fill: #FFF;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .servicesList .serviceItem {
  background-color: #bfbff975;
}

.dark-mode .react-confirm-alert-body {
  background-color: #454a4f;
  color: #DDD;
  border: 1px solid #5d6266;
}

.dark-mode .react-confirm-alert-body h1 {
  color: #FFF;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .listNotes .noteItem {
  background-color: #bfbff975;
  border: none;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .paymentListModal .paymentItem.paid {
  background: #f6ffed66;
  border-color: #f6ffed99;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .paymentListModal .paymentItem.return {
  background: #bdaf90b2;
  border-color: #e4d0a6;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .historicBookingList .historicBookingItem {
  background-color: #9595b740;
  border: 1px solid #9595b7a3;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content form#services.modeEdit {
  background: #8a7d6073;
  border-color: #9d8c67 !important;
}

/* Badges */

.dark-mode .ant-tag-green {
  background: #f6ffed1f;
}

.dark-mode .mainTable .ant-tag.ant-tag-green {
  color: #b7eb8f !important;
}

.dark-mode .mainTable .ant-tag.ant-tag-green::before {
  background-color: #b7eb8f !important;
}

.dark-mode .ant-tag-orange {
  background: #fff7e626;
}

.dark-mode .mainTable .ant-tag.ant-tag-orange {
  color: #ffd591 !important;
}

.dark-mode .mainTable .ant-tag.ant-tag-orange::before {
  background-color: #ffd591 !important;
}

.dark-mode .ant-tag-red {
  background: #fff1f01c;
}

.dark-mode .mainTable .ant-tag.ant-tag-red {
  color: #ffa39e !important;
}

.dark-mode .mainTable .ant-tag.ant-tag-red::before {
  background-color: #ffa39e !important;
}

.dark-mode .ant-table-cell .ant-tag.bloqued {
  background-color: #eeeeee12;
}

.dark-mode .ant-table-cell .ant-tag.bloqued svg path {
  fill: #d9d9d9;
}

.dark-mode .mainTable .ant-tag {
  color: #d9d9d9 !important;
}

.dark-mode .mainTable .ant-tag::before {
  background-color: #d9d9d9 !important;
}

.dark-mode .ant-picker-clear .anticon-close-circle svg {
  background-color: var(--dark-selects-bg);
}

.dark-mode .ant-picker-clear .anticon-close-circle svg path {
  background-color: #e2e4e5;
}

.dark-mode .ant-picker-dropdown .ant-picker-panel .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.dark-mode .ant-picker-dropdown .ant-picker-panel .ant-picker-cell-in-view.ant-picker-cell-range-start::before,
.dark-mode .ant-picker-dropdown .ant-picker-panel .ant-picker-cell-in-view.ant-picker-cell-range-end::before {
  background: var(--primary-color-opacity-35) !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.76);
  font-size: 12px;
}

/* .rbc-date-cell.rbc-current {

  border: solid 5px red!important;
} */

a, span {
  /*color: black!important;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.auth_Auth-form-container__2vxLn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.877);
}

.auth_Auth-form__3Jn7B {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: white; 
}

.auth_Auth-form-content__1Jys- {
  padding-left: 12%;
  padding-right: 12%;
}

.auth_Auth-form-title__2SfmE {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
}

 label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}

.auth_aLink__Kfg7D {
  color: #ED1A38!important;
}

.auth_authButton__2Scqf {
  background-color: #ED1A38!important;
  border: none!important;
}

.auth_authSpan__FQz5a {
  color: #ED1A38!important;
} 
.error-img {
  display: grid;
  place-content: center;
  margin: 20px 0 10px;
}

.error-img svg {
  width: 300px;
  height: 200px;
}
.logo-auth {
  text-align: center;
  margin-bottom: 30px;
}

.logo-auth svg {
  width: 220px;
  height: auto;
}

.header_cardContainer__2gKul {
  margin: 0.5%;
}

.header_cardBody__2cyx5 {
  display: flex;
  justify-content: space-between;
  box-shadow: 10px 10px 5px rgba(80, 78, 78, 0.062);
}

.header_centrosContainer__4fMpz {
  display: flex;
}


.header_headerSelect__1Nrpp {
  border-radius: 10px;
  padding: 2px;
  position: relative;
  right: 12px;
  border: 0px;
  outline: 0px;
}

.header_logoutIcon__2FdNw {
  padding-left: 25px;
  font-size: 22px;
  position: relative;
  top: 6.5px;
  color: red;
}

.navbar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.burguer-menu {
  font-size: 20px;
  position: relative;
  left: 18px;
  top: 2px;
}

.close-nav-btn {
  color: white;
  font-size: 25px;
  position: relative;
  left: 160px;
  top: -5px;
}

.nav-menu {
  z-index: 100;
  background-color: #3e3e3ff3;
  width: 230px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 950ms;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
  list-style: none;
  height: 50px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #363636;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  z-index: 100;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.menu-item {
  color: white !important;

  font-size: 15px;
}

.calendar_calendarContainer__5jKKe {
  width: 95%;
  margin: 0 auto;
}

/* selects */

.calendar_calendarDisplayContainer__2HsMQ {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.calendar_calendarDisplaySelect__2trUU {
  width: 10% !important;
  margin: 1%;
  padding: 0.5% !important;
}

/* Checkbox Container */
.calendar_checkBoxContainer__1eNP- {
  display: flex;
  align-items: center;
  width: 80%;
  justify-content: space-between;
}

/* Card Info Activity */

.calendar_activityCardInfo__xkQxO {
  padding: 12px 0 12px 0;
  display: flex;
  justify-content: space-around;
}

.calendar_activityCardContainer__3Cecw {
  background-color: rgba(7, 128, 7, 0.46);;
  padding: 15px;
  border-radius: 20px;
  height: 90px;
}

.calendar_anotacionesTitle__3kc6M {
  color: rgb(46, 44, 44);
  font-size: 15px;
  padding-left: 10px;
}

p {
  color: black;
}

.calendar_deleteAnotacionIcon__2P0Cf {
  color: rgba(196, 12, 12, 0.733);
}

.calendar_deleteAnotacionIcon__2P0Cf:hover {
  color: rgba(226, 9, 9, 0.644);
}

.calendar_calendarBtnsContainer__3Xkb1 {
  display: flex;
  height: 50px;
}

/* anotaciones*/

.calendar_anotacionesContainer__1XunS {
  width: 60%;
  border-radius: 20px;
  padding: 10px;
  background-color: rgba(134, 6, 134, 0.249);

}

.calendar_anotacionesBtn__1MFIg {
  position: relative;
  left: -12px;
}

.calendar_anotacionesIcon__Rrxix {
  font-size: 28px;
  color: rgba(134, 6, 134, 0.76);
}

.calendar_anotacionesIcon__Rrxix:hover {
  font-size: 28px;
  color: rgba(134, 6, 134, 0.904);
}

.calendar_addReservaIcon__3Y0bD {
  font-size: 28px;
  color: rgba(7, 128, 7, 0.671);
}

.calendar_addReservaIcon__3Y0bD:hover {
  color: rgba(7, 128, 7, 0.815);
}

p {
  margin-bottom: 0 !important;
}

/* Anotaciones description */

.calendar_anotacionContainer__38hr3 {
  display: flex;
  background-color: rgba(255, 255, 255, 0.904);
  padding: 8px;
  border-radius: 10px;
  margin-bottom: 2px;
  /* box-shadow: 2px 2px rgba(180, 25, 134, 0.507); */
}

.calendar_anotacionn__1OYG8 {
  width: 95%;
}

.calendar_descripcionAnotacion__KBRRa .calendar_weather__3fNzX {
  font-size: 12px;
}

.calendar_description__2LuRg {
  font-weight: bold;
}

.calendar_anotacionesInputData__2ttBH {
  padding-left: 5px;
}

/* subheader*/

.calendar_subheaderContainer__1Dqb_ {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px 0 20px 0;
}

/*checkboxes*/

.calendar_checkbox__RL3Dd label {
  font-size: small;
  font-weight: 400;
  position: relative;
  top: -1px;
}

.modal_modalContainer__2No37 {
  background-color: rgba(77, 77, 77, 0.859);
}

.modal_modalMed__1NzmH {
  background-color: rgba(77, 77, 77, 0.911);
}

.formularioReserva_formContainer__2eZZj {
  padding: 1%;
}

.formularioReserva_formContent__28ttC {
  display: flex;
  width: 100%;
}

.formularioReserva_One__3wux6 {
  width: 80%;
  padding: 1%;
}

.formularioReserva_Two__2LWhG {
  width: 130%;
  padding: 2%;
}

.formularioReserva_fullWidth__3WuPs {
  width: 500%;
}

/* Buttons */

.formularioReserva_formBtnsContainer__1YoNc {
  padding: 1%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.formularioReserva_añadirActividadBtn__1yMWT,
.formularioReserva_backBtn__1kt3h,
.formularioReserva_crearBorradorBtn__1AntU,
.formularioReserva_finalizarBtn__3xB4X {
  margin-right: 1%;
}

.formularioReserva_submitButtonHideTemp__fx3If {
  display: none;
}

.formularioReserva_submitContainer__18I_Q {
  display: flex;
  justify-content: center;
  width: 100%;
}

.formularioReserva_calendarioTopLayer__16ArO {
  border-radius: 10px;
  display: block;
  background-color: rgba(0, 0, 0, 0.125);
  pointer-events: none;
}

.formularioReserva_warningNewDateSelection__2igHK {
  padding: 1%;
  margin: 0 auto;
  width: 70%;
  border-radius: 20px;
  justify-content: center;
  text-align: center;
  background-color: rgb(247, 244, 241);
  margin-bottom: 1%;
}

.formularioReserva_warningNewDateBtnContainer__3jcgi {
  padding-top: 1%;
  margin: 0 auto;
  width: 40%;
  display: flex;
  justify-content: space-around;
}

.datosPersonales_inputs__2TzKT {
  padding: 1px !important;
  padding-left: 12px !important;
}

.datosPersonales_labels__2B_TF {
  padding: 0 !important;

  font-size: 12px;
}

/* Validation */
.datosPersonales_requiredFieldStar__gZ7FU {
  color: red!important;
  font-weight: 600;
}
.actividad_reservaComponent__2XpKZ {
  width: 100%;
  padding: 2%;
  background-color: rgba(95, 92, 92, 0.062);

  margin: 0 auto;
}

.actividad_formContent__aD9-K {
  width: 65%;
  margin: 0 auto;
}

.actividad_selectGroups__2Oh7I {
  background-color: rgba(95, 92, 92, 0.199);
  padding: 25px;
  border-radius: 20px;
  margin-bottom: 18px;
}

.actividad_divFlex__aaseA {
  display: inline-block;
}

.actividad_aiFillPlusCircle__2Jbdb {
  font-size: 30px;
  position: relative;
  right: 50px;
  top: 28px;
}

.actividad_adultSelectContainer__u2Oo6 {
  padding: 2.5%;
}

.actividad_saveAdultsBtn__2aiPA {
  width: 20px;
}

.actividad_kidsBtnContainer__1b3HL {
  margin: 0 auto;
  width: 100% !important;
  align-items: center !important;
  justify-content: space-around;
  margin-top: 5% !important;
  padding: 2%;
}

.actividad_kidsBtnContent__2RpoC {
  display: flex;
  padding: 2%;
  width: 90%;
  justify-content: space-around;
}

.actividad_otro__1j0r4 {
  display: none;
}

.actividad_horas__282JE {
  display: flex;
}

.actividad_dateStyle__36APg {
  display: flex;
}

.actividad_label__1P9gJ {
  width: 100%;
  margin-left: 8px;
  position: relative;
  top: 8px;
  padding-bottom: 5px;
  font-size: 12px;
}

.actividad_fechasSeleccionadas__3NSNY {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding-bottom: 3%;
}

.actividad_paxTarifaSelected__1TUFn {
  display: flex;
  justify-content: space-around;
  background-color: rgba(94, 94, 94, 0.228);
  width: 5%;
  border-radius: 10px;
  padding: 1%;
}

.actividad_formTarifaContent__1--r4 {
  display: flex;
}

.actividad_precio__1CUbd {
  position: relative;
  top: 6px;
}

.actividad_priceInput__1-VPV {
  width: 70px !important;
}

.actividad_tarifaLabel__3TXGT {
  position: relative;
  top: 8px;
  margin-right: 8px;
}

.actividad_botonesActividad__1D6Km {
  display: flex;
  justify-content: center;
  justify-content: space-around;
  padding-top: 10%;
}

.actividad_actividadSeleccionada__3q4BZ {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 10px;
  background-color: rgba(94, 94, 94, 0.123);
  padding: 1%;
  border-radius: 10px;
}

.actividad_totalPeople__3zR5o {
  padding: 2%;
}

.actividad_totalPersonas__3oW96 {
  background-color: rgba(94, 94, 94, 0.228);
  border-radius: 10px;
  margin: 1%;
  padding: 1%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.actividad_totalPersonasInfo__24q86 {
  width: 100%;
}

.actividad_totalPersonasBotones__3cjTT {
  width: 35%;
  display: flex;
  justify-content: space-around;
}

.actividad_numPersonasContent__2OIMv {
  display: flex;
  width: 31%;
  justify-content: space-between;
}

.actividad_saveRecursosBtn__3yMKJ {
  position: relative;
  left: -10px;
  float: right;
  border: 0px solid black;
  background-color: rgba(11, 126, 20, 0.514);
  padding: 4px;

  margin-right: 10px;
}

.actividad_selectedRecurso__1ltFj {
  margin-top: 3px;
  padding: 8px 8px;
  padding-left: 10px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.699);
}

.actividad_inputsRecursos__n-ciW {
  width: 100%;
  padding: 15px;
  padding-bottom: 20px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.363);
}

.actividad_recursosInputs__xZuiD {
  width: 100%;
  margin: 0 auto;
}

.actividad_inputRecursosInfo__1gJ-U {
  text-align: center;
}

.actividad_colorRecursoDis__20MXR {
  font-weight: bold;
  display: flex;
  justify-content: space-around;
}
.actividad_numeroDePersonas__18NHa {
  color: green !important;
}

/* Totales tarifa */
.actividad_totalesTarifa__3I0VC {
  padding-bottom: 5px;
}

/* Flujo mas de una actividad */
.actividad_btnGreen__1FGrH {
  background-color: green;
  color: #ffffff;
}

.actividad_addAnotherActivityBtnContainer__11PSM {
  display: flex;
  width: 100%;
  justify-content: center;
}
.actividad_addAnotherActivityBtn__2-esS {
  margin-top: 10px;
  text-align: center;
  background-color: green !important;
  color: white !important;
}

.actividad_actividadGuardadaFlujo__1agX3 {
  display: flex;
  justify-content: space-around;
  background-color: rgba(0, 0, 255, 0.082);
  border-radius: 15px;
  padding: 1%;
  margin-bottom: 10px;
}

.actividad_fechaContainer__1KHwb {
  width: 20%;
  display: flex;
  justify-content: space-between;
}

.actividad_infoActividadYTarifas__1piZz {
  padding-left: 1%;

  width: 40%;
}

.actividad_actividadTitle__1MuMe {
  font-weight: bold;
}

.actividad_btnsContainerTarifa__1AVcQ {
  display: flex;
  width: 60%;
  justify-content: space-around;
  padding-top: 6px;
  padding-right: 15px;
  margin: 0 auto;
}

.actividad_tarifasFinal__xpdZi {
  padding: 2px 2px;
}

/* .deleteActividadContainer {
  display: flex;
  justify-content: flex-end;
  width: 80%;
} */

.actividad_trashIcon__3B2Xo {
  position: relative;
  top: 10px;
  left: 10px;
}
.actividad_dateInfoWarning__2tcSg {
  background-color: rgb(241, 215, 206);
  text-align: center;
  padding: 1%;
  border-radius: 20px;
  width: 65%;
  margin: 1% auto;
}

.actividad_warningInfoContainerBtns__1jBSx {
  padding: 2% 1%;
  width: 60%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}

.actividad_testColour__3xely {
  background-color: aqua;
}

.actividad_finalBtns__3CcYD {
  display: flex;
}

.actividad_tarifasInfo__oaohX {
  display: flex;
  padding-left: 6%;
}
.actividad_precioYUdsInfo__3vSsv {
  padding-left: 6%;
}

.actividad_backBtnContainer__3MZn9 {
  display: flex;
  justify-content: flex-end;
  padding-right: 5%;
  padding-top: 1%;
}

.actividad_tarifaNamePriceContainer__2QHMf {
  padding: 1%;
}

.actividad_modifyPriceContainer__16y6I {
  display: flex;
  justify-content: space-between;
  /* width: 30%; */
  padding: 1%;
}

/* .discountSEction {
  padding: 1%;
} */

.actividad_porcentaje__1qM-m {
  padding: 1%;
}

.actividad_porcentajeContainer__SFHiz {
  padding-top: 10px;
  display: flex;
}

.actividad_discountBtns__1Wa9n {
  padding: 1%;
  display: flex;
  margin: 0 auto;
  width: 50%;
  justify-content: space-between;
}

.actividad_newPriceContainer__dyTMQ {
  display: flex;
}

.actividad_newPriceInput__1Z4_R {
  height: 30px;
}
.actividad_newPriceLabel__3YWLb {
  width: 60%;
  position: relative;
  top: 3px;
}
.actividad_precioModified__3DWZJ {
  text-decoration: line-through;
  color: red;
}

.actividad_newPriceAplied__2ozGr {
  background-color: rgba(26, 239, 26, 0.32);
  padding: 1%;
  border-radius: 20px;
  width: 35%;
  text-align: center;
}

.actividad_percentageSpans__3NcLO {
  background-color: rgba(26, 239, 26, 0.32);
  padding: 1%;
  margin-right: 1%;
  width: 10%;
  text-align: center;
  height: 30px;
}

.actividad_pricesUpdates__Vqhln {
  background-color: aquamarine;
  display: flex;
}

.actividad_noThroughLine__22hTq {
  text-decoration: none !important;
}

.actividad_tarifaNameAndPrice__PahNp {
  display: flex;
}

.actividad_tarifaTrash__1Ah7v {
  position: relative;
  top: 8px;
  color: rgba(255, 0, 0, 0.74);
}

.actividad_tarifaTrash__1Ah7v:hover {
  color: red;
}

.actividad_modificarTarifaContainer__2U3NU {
  display: flex;
  justify-content: space-around;
  background-color: #ffffffa8;
  padding: 1%;
  margin: 1%;

}

.actividad_eliminarBtn__20nta {
  margin-left: 5px;
}

.resumenreserva_resumenReservaContainer__17HHs {
  padding: 25px 5px;
  border-radius: 20px;

  width: 100%;
  margin: 0 auto;
}

/*Datos personales*/
.resumenreserva_datosContainer__IwylZ {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  padding: 10px 35px;
  background-color: rgba(187, 184, 184, 0.146);
  border-radius: 20px;
}

.resumenreserva_titleResumen__1cahg {
  font-size: 14px;
  font-weight: 500;
  margin-right: 5px;
}
.resumenreserva_titles__3j6UC {
  font-size: 13px;
}

.resumenreserva_modificarDatosPersonalesBtnContainer__1vFH4 {
  display: flex;
  align-items: center;
}

/* .modDatosPersonalesBtn {
  position: relative;
  top: 130px;
  left: -20px;
} */

.resumenreserva_summaryActivityContainer__eyObv {
  width: 80%;
  border-radius: 10px;
  padding: 1%;
  justify-content: space-around;
  margin: 0 auto;
}

.resumenreserva_selectedActivityContainer__42zOC {
  width: 60%;
}

.resumenreserva_buttonActivityContainer__uN529 {
  display: flex;
  justify-content: space-around;
  width: 30%;
}

/*Servicios adicionales*/
.resumenreserva_servicioAdicional__37TpC {
  display: flex;
  width: 75%;
  justify-content: flex-end;
  padding-top: 1%;
  padding-bottom: 0.5%;
  margin: 0 auto;
}

.resumenreserva_servicioAdicionalData__1rD_I {
  width: 75%;
}

.resumenreserva_formServicioAdicional__2aRo3 {
  margin-top: 2%;
  width: 96%;
  margin: 0 auto;
  padding: 2%;
}

.resumenreserva_summaryActivity__nyFaw {
  padding: 2%;
}

.resumenreserva_containerPagos__HkxhO {
  padding-top: 20px;
  width: 70%;

  display: flex;
  justify-content: space-around;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.resumenreserva_containerPagos__HkxhO > div {
  width: 100%;
}

.resumenreserva_totalPrecioReservaContent__jsVEn {
  background-color: rgba(149, 145, 145, 0.094);
  width: 50%;
  display: flex;
  border-radius: 10px;
  padding: 3%;
  align-items: center;
  justify-content: center;
}

.resumenreserva_totalPrecioReservaContainer__2cn2p {
  position: relative;
  top: -65px;
  background-color: rgba(146, 145, 145, 0.618);
  padding: 20px;
  border-radius: 20px;
}

.resumenreserva_btnTotales__38fVR {
  position: relative;
  top: 20px;
}

/*Resumen pago*/

.resumenreserva_paymentContent__3y-0j {
  background-color: rgba(144, 147, 146, 0.507);
  border-radius: 20px;
  padding: 10px;
}

.resumenreserva_cantidadDelPago__vtnW_,
.resumenreserva_fechaDelPago__2fU64 {
  display: flex;
}

.resumenreserva_labelsPayment__3yvqv {
  width: 65%;
  position: relative;
  top: 15px;
  left: 20px;
}

.resumenreserva_resumenContainer__2amhY {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.resumenreserva_resumenContent__1jNH6 {
  padding: 10px;
  width: 30%;
  background-color: white;
  border-radius: 20px;
}

.resumenreserva_buttonContainerHistorial__SiO2z {
  padding: 1%;

  margin: 0 auto;
  width: 70%;
  padding-bottom: 50px;
}

.resumenreserva_modificarPreciosBtn__3cP_F {
  margin-right: 1%;
}

.resumenreserva_buttonContainer__h1kQ5 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
/* Método de pago*/

.resumenreserva_totalPagoContainer__1Xsnb {
  justify-content: center;
  width: 70%;
  margin: 0 auto;
  padding: 0 !important;
  padding-top: 2% !important;
  padding-bottom: 2% !important;
}

.resumenreserva_actividadUnidad__6sxqw {
  background-color: rgba(128, 128, 128, 0.237);
  display: flex;
  justify-content: space-around;
  padding: 10px 10px;
  border-radius: 20px;
}
.resumenreserva_serviciosAdBtnAct__3OIa7 {
  height: 30px;
  position: relative;
  top: 15px;
}

.resumenreserva_serviciosAdBtn__3Ag5U {
  width: 90px;
  margin-left: 5px;
  font-size: 10px !important;
}

.resumenreserva_serviciosAdicionalesContainer__1uRs3 {
  width: 90%;
  margin: 0 auto;
}

.resumenreserva_totalespago__RCfR_ {
  display: flex;
  width: 100%;
}

.resumenreserva_submitBtnContainer__KpzaZ {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.resumenreserva_submit__1NScz {
  background-color: rgba(0, 128, 0, 0.87) !important;
  border: none !important;
}

/* edit personal data */

.resumenreserva_formGroupEdit__2T6_3 {
  padding: 10px 35px;
  background-color: rgba(187, 184, 184, 0.146);
  border-radius: 20px;
  width: 80%;
  margin: 0 auto;
}

.resumenreserva_labels__2fvvg {
  padding: 0 !important;
  width: 100px;
  height: 15px;
  font-size: 14px;
  padding-left: 10px !important;
}

.resumenreserva_inputs__3WnWz {
  padding: 0 !important;
  height: 20px;
  padding-left: 8px !important;
}

.resumenreserva_displayformFlex__p_W9g {
  display: flex;
}

.resumenreserva_editDatosPersonalesBtnContainer__3jG6a {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  position: relative;
  left: -20px;
  padding-top: 5px;
}

.resumenreserva_cancelBtn__10tzM {
  margin-left: 3px;
}

.resumenreserva_precioPagosStyles__14k59 {
  width: 100%;
}

.resumenreserva_añadirOtroPagoStyles__2h-j2 {
  display: flex;
}

.resumenreserva_paidAmount__GIwBV {
  padding: 1%;
  background-color: rgba(107, 218, 107, 0.445);
  border-radius: 20px;
}

.resumenreserva_inputFormPayment__1l-br {
  margin-top: 10px;
  width: 100px;

}

.anotacionesInternas_anotacionesInternasContainer__2OrbJ {
  width: 100%;
  margin: 0 auto;
  margin-top: 3%;
  margin-bottom: 3%;
}

.anotacionesInternas_observacionContent__3U177 {
  margin: 1.5%;
}

.anotacionesInternas_formContainer__wWgiV {
    margin-top: 7%;
}

.anotacionesInternas_observacionForm__DGCq8 {
    display: flex;
}

.anotacionesInternas_observacionLabel__3KxfG {
    padding-right: 2.5%;
}

.anotacionesInternas_buttonContainer__OCTGa {
    float: right;
}

.anotacionesInternas_anotacionesItem__1yh97 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.anotacionesInternas_anotacionesItem__1yh97:not(:last-child) {
  border-bottom: 1px solid #dcdcdc;
}

.anotacionesInternas_trashIcon__1RuAv {
  position: relative;
  color: #8c8c8c;
  padding: 8px;
  border: 1px solid #8c8c8c;
  border-radius: 8px;
}

.anotacionesInternas_trashIcon__1RuAv:hover {
  color: #ed5e68;
  cursor: pointer;
}


.anotacionesInternasresume_anotacionesInternasContainer__30sFn {
  width: 100%;
  margin: 0 auto;
  margin-top: 3%;
  margin-bottom: 3%;
}

.anotacionesInternasresume_observacionContent__2LJcX {
  margin: 1.5%;
}

.anotacionesInternasresume_formContainer__vAdVN {
    margin-top: 7%;
}

.anotacionesInternasresume_observacionForm__2OtGn {
    display: flex;
}

.anotacionesInternasresume_observacionLabel__1CR7G {
    padding-right: 2.5%;
}

.anotacionesInternasresume_buttonContainer__2Gmzk {
    float: right;
}

.anotacionesInternasresume_anotacionesItem__Rxg_B {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.anotacionesInternasresume_anotacionesItem__Rxg_B:not(:last-child) {
  border-bottom: 1px solid #dcdcdc;
}

.anotacionesInternasresume_trashIcon__3Uu_L {
  position: relative;
  color: #8c8c8c;
  padding: 8px;
  border: 1px solid #8c8c8c;
  border-radius: 8px;
}

.anotacionesInternasresume_trashIcon__3Uu_L:hover {
  color: #ed5e68;
  cursor: pointer;
}


.reservaBorrador_reservaBorradorContainer__3BDrG {
    margin: 2%;
}

.reservaBorrador_summaryBorradorContainer__3cCTf {
    margin: 2%;
}

.reservaBorrador_btnContainer__1LYDx {
    display: flex;
    justify-content: flex-end;
}

.reservaBorrador_guardarBtn__3KxE6 {
    margin-right: 1%;
}
.infoReserva_infoContainer__2uirp {
  width: 100%;
  /*padding-top: 1%;*/
  padding: 25px;
}

.infoReserva_modificarReservaContainer__1ld2L {
  width: 128%;
}

.infoReserva_datosDeReserva__1TZjr {
  display: flex;
  justify-content: space-between;

  width: 80%;
  margin: 0 auto;
}

.infoReserva_infoDeActividad__3hrG3 {
  display: flex;
}

.infoReserva_deleteActividadnIcon__kcA6r {
  color: rgba(255, 0, 0, 0.578);
  position: relative;
  font-size: 13px;
  top: 11px;
  left: -32px;
}

.infoReserva_deleteActividadnIcon__kcA6r:hover {
  color: red;
}

.infoReserva_reservaTotales__DLM_8 {
  display: flex;
}

.infoReserva_datos__3t8eh {
  width: 60%;
  position: relative;
}

.infoReserva_datos__3t8eh > button {
  position: absolute;
  top: 0;
  margin: 0 0 0 auto;
  right: 0;
  border: 0;
  background: none;
  text-align: right;
  left: unset;
}

.infoReserva_datos__3t8eh > button i {
  font-size: 20px;
}

.infoReserva_datosPersonalesBtns__1KH_q {
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
}

.infoReserva_datosPersonalesBtns__1KH_q > button:not(:first-child) {
  margin-top: 10px;
}

.infoReserva_datosPersonalesBtns__1KH_q > button {
  background-color: transparent;
  border-color: transparent;
  padding: 0;
}

.infoReserva_datosPersonalesBtns__1KH_q > button:hover,
.infoReserva_datosPersonalesBtns__1KH_q > button:focus,
.infoReserva_datosPersonalesBtns__1KH_q > button:active {
  border-color: transparent;
  opacity: .5;
}

.infoReserva_datosPersonalesBtns__1KH_q > button:first-child i {
  color: #38ba7c;
  font-size: 20px;
}

.infoReserva_datosPersonalesBtns__1KH_q > button:last-child i {
  color: #c36069;
  font-size: 20px;
}

.infoReserva_resumenPagos__gweS5 {
  width: 35%;
}

.infoReserva_parcial__2Pyst {
  background-color: rgba(255, 166, 0, 0.256);
  padding: 12px;
  border-radius: 20px;
  height: 220px;
}

.infoReserva_active__TbLtp {
  background-color: rgba(20, 247, 20, 0.141);
  padding: 20px 20px;
  border-radius: 10px;
  /* width: 40%; */
  height: 220px;
}

.infoReserva_btnContainerPagos__2PmxK {
  width: 100%;
  position: relative;
  top: 10%;
}

.infoReserva_noPay__MzEGE {
  background-color: rgba(255, 0, 0, 0.181);
  padding: 12px;
  border-radius: 10px;
  height: 220px;
}

.infoReserva_btnContainer__3ZYtl {
  width: 30%;
  position: relative;
  top: 7px;
}

.infoReserva_infoDeActividad__3hrG3 {
  width: 80%;
  margin: 0 auto;
  padding: 12px 20px;
  background-color: rgba(185, 183, 183, 0.187);
  border-radius: 10px;
  margin-top: 1%;
  align-items: center;
  position: relative;
}

.infoReserva_infoDeActividad__3hrG3 .infoReserva_btnContainer__3ZYtl {
  top: 0;
  text-align: right;
}

.infoReserva_infoDeActividad__3hrG3 .infoReserva_btnContainer__3ZYtl button {
  margin-right: 10px;
}

.infoReserva_infoDeActividad__3hrG3 .infoReserva_btnContainer__3ZYtl span {
  background-color: rgb(148, 148, 148);
  padding: 5px 13px;
  border-radius: 50%;
  position: relative;
}

.infoReserva_infoDeActividad__3hrG3 .infoReserva_btnContainer__3ZYtl span:hover {
  cursor: pointer;
}

.infoReserva_infoDeActividad__3hrG3 .infoReserva_btnContainer__3ZYtl span i {
  color: #FFF;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.infoReserva_infoDeActividad__3hrG3 .infoReserva_btnContainer__3ZYtl span:not(:first-child) {
  margin-left: 8px;
}

.infoReserva_infoDeActividad__3hrG3 .infoReserva_btnContainer__3ZYtl .infoReserva_deleteActivityBtn__2L3bf {
  background-color: #c36069;
}

.infoReserva_infoDeActividad__3hrG3 .infoReserva_btnContainer__3ZYtl .infoReserva_historicActivityBtn__1mCiv {
  background-color: #77b1c8;
}

.infoReserva_actividad__1TTWV {
  width: 80%;
}

.infoReserva_reservaTotales__DLM_8 {
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  width: 80%;
}

.infoReserva_totales__2xJQR {
  margin-top: 6%;
  border-radius: 20px;
  padding: 20px 30px;
  background-color: rgba(159, 159, 163, 0.253);
}

.infoReserva_totales__2xJQR button {
  margin-top: 10px;
}

.infoReserva_anotacionesContainer__bPB9x {
  padding: 20px 5px 0;
  width: 80%;
  display: flex;
  justify-content: flex-end;
  margin: auto;
}

.infoReserva_btnsContainer__1iPoe {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding: 1%;
  border-top: 1px solid #d8d8d8;
  padding-top: 30px;
  margin-top: 18px;
}

.infoReserva_btnsContainer__1iPoe button:not(:first-child) {
  margin-left: 20px;
}

.infoReserva_btnContainerHistorial__WXyGP {
  position: relative;

  float: right;
}
.infoReserva_title__1LaXx, .infoReserva_title__1LaXx b {
  font-size: 15px;
}

h3 b {
  font-size: 2.3rem;
}

/* edit */

.infoReserva_placeholderModify___6xqo {
  border: none;
  border-bottom: 1px solid grey;
  padding-left: 10px;
}

.infoReserva_placeholderModify___6xqo:focus-visible {
  border-color: rgb(35, 78, 143);
  outline: none;
}

.infoReserva_addAnotherActBtnContainer__2UaK3 {
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  padding: 16px;
}

.infoReserva_calendarioAñadirActividad__30VAe {
  position: relative;
  left: -55px;
}

/* //test hide actividad */

.infoReserva_hideActividad__3Zh-b {
  display: none;
}

.infoReserva_disappearActividad__1LiAE {
  display: none;
}

.infoReserva_tarifaDetail__3eeB8 {
  font-weight: bold;
  margin-top: 5px;
}

.historialPrecios_historialContainer__2yEZQ {
  padding: 2% !important;
  margin: 0 auto;
  width: 100%;
}

.historialPrecios_historialContent__bbOn8 {
 margin: 0 auto;
  display: flex;
  justify-content: space-around;
  width: 90%;
  background-color: rgba(165, 167, 167, 0.185);
  padding: 5px;
  border-radius: 20px;
  margin-bottom: 10px;
}

.historialPrecios_observacionBtn__HkXau {
  position: relative;
  top: 27px;
}

.historialPrecios_labelshistorialPago__1Z-hS {
  font-size: 14px;
  font-weight: 500;
}

.historialPrecios_infoPayment__36KJv {
  font-size: 14px;
  font-weight: 400;
}
.historialPrecios_textoObservaciones__ycfP5 {
  width: 80%;
  padding-top: 2%;
  padding-bottom: 2%;
  margin: 0 auto;
}

.historialPrecios_closeBtn__23zZv {
  padding: 0 !important;
  width: 30px;
  position: relative;

  left: 94%;
  color: white !important;
  background-color: red !important;
  border: none !important;
}
.historialPrecios_textoObservacion__3Uz_H {
  margin-top: 1%;
}

.historialPrecios_lastPrice__2ZEed {
  margin-left: 2% !important;
  color: green;
}

.anadirpago_resumenReservaContainer__1PbSb {
    padding: 25px 5px;
    border-radius: 20px;

    width: 100%;
    margin: 0 auto;
}

/*Datos personales*/
.anadirpago_datosContainer__3Wir3 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
    padding: 10px 35px;
    background-color: rgba(187, 184, 184, 0.146);
    border-radius: 20px;
}

.anadirpago_titleResumen__1mEy0 {
    font-size: 14px;
    font-weight: 500;
    margin-right: 5px;
}
.anadirpago_titles__1Sq_j {
    font-size: 13px;
}

.anadirpago_modificarDatosPersonalesBtnContainer__36fd2 {
    display: flex;
    align-items: center;
}

/* .modDatosPersonalesBtn {
  position: relative;
  top: 130px;
  left: -20px;
} */

.anadirpago_summaryActivityContainer__3Akie {
    width: 80%;
    border-radius: 10px;
    padding: 1%;
    justify-content: space-around;
    margin: 0 auto;
}

.anadirpago_selectedActivityContainer__2NBDT {
    width: 60%;
}

.anadirpago_buttonActivityContainer__3QdEj {
    display: flex;
    justify-content: space-around;
    width: 30%;
}

/*Servicios adicionales*/
.anadirpago_servicioAdicional__3axMQ {
    display: flex;
    width: 75%;
    justify-content: flex-end;
    padding-top: 1%;
    padding-bottom: 0.5%;
    margin: 0 auto;
}

.anadirpago_servicioAdicionalData__IyJkc {
    width: 75%;
}

.anadirpago_formServicioAdicional__1qf8H {
    margin-top: 2%;
    width: 96%;
    margin: 0 auto;
    padding: 2%;
}

.anadirpago_summaryActivity__3UxxE {
    padding: 2%;
}

.anadirpago_containerPagos__zb22U {
    padding-top: 20px;
    width: 70%;

    display: flex;
    justify-content: space-around;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.anadirpago_containerPagos__zb22U > div {
    width: 100%;
}

.anadirpago_totalPrecioReservaContent__rqjqn {
    background-color: rgba(149, 145, 145, 0.094);
    width: 50%;
    display: flex;
    border-radius: 10px;
    padding: 3%;
    align-items: center;
    justify-content: center;
}

.anadirpago_totalPrecioReservaContainer__3p7iG {
    position: relative;
    top: -65px;
    background-color: rgba(146, 145, 145, 0.618);
    padding: 20px;
    border-radius: 20px;
}

.anadirpago_btnTotales__2HqxI {
    position: relative;
    top: 20px;
}

/*Resumen pago*/

.anadirpago_paymentContent__WODuM {
    background-color: rgba(144, 147, 146, 0.507);
    border-radius: 20px;
    padding: 10px;
}

.anadirpago_cantidadDelPago__29Dt4,
.anadirpago_fechaDelPago__3HUz4 {
    display: flex;
}

.anadirpago_labelsPayment__1J51d {
    width: 65%;
    position: relative;
    top: 0px;
    left: 0px;
}

.anadirpago_resumenContainer__2iyVa {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.anadirpago_btnContainerPagos__nPFhy {
    width: 100%;
    position: relative;
    top: 10%;
    margin-bottom: 20px;
}

.anadirpago_resumenContent__2P9RT {
    padding: 10px;
    width: 30%;
    background-color: white;
    border-radius: 20px;
}

.anadirpago_buttonContainerHistorial__-VJ9Y {
    padding: 1%;

    margin: 0 auto;
    width: 70%;
    padding-bottom: 50px;
}

.anadirpago_modificarPreciosBtn__fGFC3 {
    margin-right: 1%;
}

.anadirpago_buttonContainer__3RE6H {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
/* Método de pago*/

.anadirpago_totalPagoContainer__3m6YE {
    justify-content: center;
    width: 70%;
    margin: 0 auto;
    padding: 0 !important;
    padding-top: 2% !important;
    padding-bottom: 2% !important;
}

.anadirpago_actividadUnidad__2bl0G {
    background-color: rgba(128, 128, 128, 0.237);
    display: flex;
    justify-content: space-around;
    padding: 10px 10px;
    border-radius: 20px;
}
.anadirpago_serviciosAdBtnAct__1HzIs {
    height: 30px;
    position: relative;
    top: 15px;
}

.anadirpago_serviciosAdBtn__1KTEs {
    width: 90px;
    margin-left: 5px;
    font-size: 10px !important;
}

.anadirpago_serviciosAdicionalesContainer__2knf1 {
    width: 90%;
    margin: 0 auto;
}

.anadirpago_totalespago__366dy {
    display: flex;
    width: 100%;
}

.anadirpago_submitBtnContainer__AZOiK {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.anadirpago_submit__1PDFv {
    background-color: rgba(0, 128, 0, 0.87) !important;
    border: none !important;
}

/* edit personal data */

.anadirpago_formGroupEdit__29rt- {
    padding: 10px 35px;
    background-color: rgba(187, 184, 184, 0.146);
    border-radius: 20px;
    width: 80%;
    margin: 0 auto;
}

.anadirpago_labels__3Nn-y {
    padding: 0 !important;
    width: 100px;
    height: 15px;
    font-size: 14px;
    padding-left: 10px !important;
}

.anadirpago_inputs__ZKY0b {
    padding: 0 !important;
    height: 20px;
    padding-left: 8px !important;
}

.anadirpago_displayformFlex__ZDbTW {
    display: flex;
}

.anadirpago_editDatosPersonalesBtnContainer__1SxFc {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    position: relative;
    left: -20px;
    padding-top: 5px;
}

.anadirpago_cancelBtn__3SHeY {
    margin-left: 3px;
}

.anadirpago_precioPagosStyles__1leTI {
    width: 100%;
}

.anadirpago_añadirOtroPagoStyles__1NsMf {
    display: flex;
}

.anadirpago_paidAmount__gsdym {
    padding: 1%;
    background-color: rgba(107, 218, 107, 0.445);
    border-radius: 20px;
}

.anadirpago_inputFormPayment__3uYp6 {
    margin-top: 10px;
    width: 100px;

}

.modificarPrecio_modificarPrecioContainer__x6Wvl {
  display: flex;
  justify-content: space-around;
  width: 80%;
  margin: 0 auto;
}

.modificarPrecio_btnsContainer__2d4q6 {
  display: flex;
  justify-content: space-between;
  width: 16%;
  height: 40px;
}

.modificarPrecio_dd__2moot {
  display: flex;
  height: 35px;
}

.modificarPrecio_porcentaje__BXhWH {
  font-size: 22px;
}

.modificarPrecio_porCiento__2nUuf {
  width: 50%;
}

.modificarPrecio_saveBtn__yFo1v {
    float: right;
  height: 35px;
 
  margin-top: 5%;
}

.modificarPrecio_observacionesContainer__2JODG {
    margin-top: 8%;
}
.modificarPrecio_observacionLbl__3DkRj {
    margin-right: 3%;
}



.modificarPago_detallePagoContainer__NXZ8J {
  margin: 0 auto;
  width: 80%;
  padding: 2%;
}

.modificarPago_precioTotal__3SF97 {
    padding: 3%;
    margin-left: 5%;
}

.modificarPago_detallePago__2u3JE {
  display: flex;
  margin-top: 15%;
}

.modificarPago_detallePagado__2vHUO {
  margin-left: 20%;
}

.modificarPago_btnContainer__3Wbbq {
  width: 100%;
}

.modificarPago_devBtn__26AVK {
  position: relative;
  top: 10px;height: 40px;

}

.modificarPago_cantidadInputsContainer__5dNLN {
  width: 100%;
  margin-top: 2%;
  margin-bottom: 2%;
}

.modificarPago_detallePagoRestante__3XTjV {
display: flex;
margin-bottom: 3%;
}

.modificarPago_pagoRestante__qgMye {
  width: 30%;
}
.modificarPago_formContainer__2L6Lo {
  display: flex;
}

.modificarPago_formContent__aG5PG {
  display: flex;
}

.modificarPago_labels__1HIn3 {
  width: 80%;
}

.modificarPago_btnsContainer__3aR5a {
  display: flex;
  justify-content: center;
  padding-bottom: 3%;
}

.modificarPago_guardarBtn__Dxhw- {
  margin-right: 1%;
}

.anotaciones_anotacionesContainer__2b2NU {
  width: 100%;
  float: right;
}

.anotaciones_formselect__3s0Qk {
  width: 40px !important;
}

.anotaciones_buttonContainer__1xiJo {
  width: 100%;
  padding: 1% 3% 0% 0%;
  display: flex;
  justify-content: flex-end;
}

.anotaciones_saveBtn__1ixWo {
  margin-right: 1%;
}

.infoGuia_infoGuiaContainer__3rnTC {
  display: flex;
  padding: 1.5%;
}

.infoGuia_headerGuiaActividad__1m74- {
    position: relative;
    left: 20px;
}

button {
  position: relative;
  left: 1%;
  border-radius: 5px !important;
}

 span {
  /*color: black!important;*/
 }

.sintesisActividad_pageContainer__30ffF {
  /* width: 100vw;
  height: 100vh; */
  /* background-color: rgba(0, 0, 0, 0.374); */
  display: flex;
  justify-content: center;
  align-items: center;
}

.sintesisActividad_modalContainer__3c7a_ {
  width: 85%;
  background-color: rgb(255, 255, 255);
  padding: 2%;
  border-radius: 10px;
}

.sintesisActividad_numTotalContent__3CR7_ {
  width: 50%;
}
.sintesisActividad_tablesContainer__1vRAB {
  background-color: rgba(230, 230, 230, 0.461);
  display: flex;
  justify-content: space-between;
  margin-bottom: 2%;
  margin-top: 2%;
}

.sintesisActividad_btnContainer__2lFMG {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding-top: 5%;
}

.sintesisActividad_redCircle__OEiAt {
  color: red;
  padding-right: 1.5%;
  font-size: 11px;
}

.sintesisActividad_orangeCircle__37x2T {
  color: orange;
  padding-right: 1.5%;
  font-size: 11px;
}
.sintesisActividad_greenCircle__2IE6q {
  color: green;
  padding-right: 1.5%;
  font-size: 11px;
}
.sintesisActividad_yellowCircle__1R2x_ {
  color: yellow;
  padding-right: 1.5%;
  font-size: 11px;
}

/*****/
.sintesisActividad_specificsContent__1cqzX {
  margin-top: 4%;
}

.sintesisActividad_pestañasContainer__4shSB {
  display: flex;
  justify-content: space-around;
}

.sintesisActividad_pestaña1__1DjlY,
.sintesisActividad_pestaña2__3XDPo,
.sintesisActividad_pestaña3__3mPMa {
  display: flex;
  align-items: center;
  padding: 1%;

  border-radius: 2px;
  width: 150px;
}

.sintesisActividad_pestaña2__3XDPo {
  background-color: rgba(0, 255, 94, 0.219);
}

.sintesisActividad_pestaña3__3mPMa,
.sintesisActividad_pestaña1__1DjlY
/* .pestaña2 */
{ 
  background-color: rgba(211, 205, 205, 0.402);
}

.sintesisActividad_reservasTableContainer__8Y9pY {
  background-color: rgba(230, 230, 230, 0.461);
  padding: 2%;
  border-radius: 20px;
}

.sintesisActividad_buscadorContainer__2LYlM {
  display: flex;
  height: 10vh;
}
.sintesisActividad_checkboxContainer__3TBY3 {
  display: flex;
  width: 80%;
  justify-content: space-between;
}

.sintesisActividad_checkboxes__jJv8h {
  display: inline;
  padding: 1%;
}

/* recursos */

.sintesisActividad_recursosContainer__2poFP {
  display: flex;
  justify-content: space-around;
}

.sintesisActividad_reasignarSelectorContainer__2FmA4 {
  padding: 2%;
}

.sintesisActividad_asignarRecursoContainer__SfsT1 {
  padding-left: 2%;
  display: flex;
  justify-content: space-between;
  width: 40%;
}

/* GUIA */

.sintesisActividad_asignados__225xI {
  color: red;
}

.sintesisActividad_asignadosContainer__3jArA,
.sintesisActividad_disponiblesContainer__1QRoE,
.sintesisActividad_extraContainer__3laf9 {
  display: flex;
  padding: 1.5%;
}

.sintesisActividad_guia__o3WaV {
  margin-right: 10px;
}

.carousel_carouselContent__23LeG {
  border-radius: 12px;
  padding: 5px;
  background-color: #80808310;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.carousel_carouselDates__3LP2F {
  display: flex;
  justify-content: center;
  position: relative;

  top: 3px;
}

.carousel_date__11Drr {
  width: 95px;
  color: #656567;
  font-weight: 450;
  text-align: center;
}

.carousel_date__11Drr:hover {
  cursor: pointer;
}

.carousel_todayDate__2rxrt {
  width: 97px;
  color: #656567;
  font-weight: bolder;
  text-align: center;
}

/* carousel arrows */

.carousel_arrows__1yByw {
  font-size: 25px;
  color: #656567;
  position: relative;
  margin-left: 10px;
}

.carousel_dateBtn__1p7qV {
  margin-left: 2px;
  margin-right: 2px;
 height: 18px;
 border: none;
 background-color: rgba(205, 205, 206, 0.274);
}

.tablebooking_header__2j2kg {
    margin-bottom: 20px;
}
.tablebooking_icon__D2PWg svg {
    font-size: unset;
    color: unset;
}
.tablebooking_button__1y_2G {
    background-color: var(--primary-color);
    padding: 5px 12px !important;
    height: auto;
    border-radius: 40px !important;
}
.tablebooking_button__1y_2G:hover {
    background-color: var(--primary-color-dark) !important;
}
.tablebooking_button__1y_2G span, .tablebooking_button__1y_2G p {
    color: #FFF !important;
}

.tablebooking_button__1y_2G span {
    margin-right: 5px;
    font-size: 20px;
    line-height: 12px;
}
.activityForm {
    background-color: ghostwhite;
    /* background-color: #f2f2f2; */
    padding: 20px;
    border-radius: 15px;
    margin-top: 10px;
    border: 1px solid #d8d8d8;
}

.activityForm .ant-divider .ant-divider-inner-text {
    font-size: 16px;
}

.activityFormFirst {
    padding-right: 20px;
}

.activityFormSecond {
    padding-left: 20px;
}

.activityFormFirst .ant-select-in-form-item {
    width: 100%;
}

.activityFormFirst .ant-input-number-in-form-item {
    width: 50px;
}

.activityFormFirst .ant-input-number .ant-input-number-handler-wrap,
.modalTarifas .ant-input-number .ant-input-number-handler-wrap {
    display: none;
}

.activityFormFirst .ant-input-number-group-addon .anticon.anticon-user {
    display: block;
}

.modalTarifas .okBtn {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.modalTarifas .okBtn span {
    color: #FFF !important;
}

.modalTarifas .okBtn:hover {
    background-color: var(--primary-color-dark);
    border-color: var(--primary-color-dark);
}

.modalTarifas .okBtn:disabled {
    opacity: .5;
}

.resumeRateBox {
    background-color: rgb(236, 236, 236);
    padding: 20px;
    border-radius: 10px;
    margin-top: 15px;
}

.resumeRateBox p, .resumeRateBox b {
    font-size: 14px;
}

.resumeFinishRateBox {
    background-color: rgb(236, 236, 236);
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.resumeFinishRateBox .actionBtns {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.resumeFinishRateBox .actionBtns .iconCircle {
    background-color: #8c8c8c;
    border-radius: 50% !important;
    padding-inline: 6px;
    height: 27px;
}

.resumeFinishRateBox .actionBtns .iconCircle.editBtn {
    background-color: #5675ae;
}

.resumeFinishRateBox .actionBtns .iconCircle.deleteBtn {
    background-color: #ae565e;
}

.resumeFinishRateBox .actionBtns .iconCircle:not(:first-child) {
    margin-left: 5px;
}

.resumeFinishRateBox .actionBtns .iconCircle svg {
    font-size: 15px;
}

.resumeFinishRateBox .actionBtns .iconCircle.disabledClick {
    opacity: .5;
}

.resource-input-value .ant-input-number .ant-input-number-input {
    padding-inline: 5px;
    text-align: center;
    font-weight: bold;
}

.ant-select-dropdown #resources_list + .rc-virtual-list .ant-select-item-option-selected {
    opacity: .5;
    background-color: #ff4d4f33 !important;
}

.ant-select-dropdown #resources_list + .rc-virtual-list .ant-select-item-option-selected .ant-select-item-option-state svg {
    fill: var(--primary-color-dark);
}

.ant-select-dropdown #resources_list + .rc-virtual-list .ant-select-item-option-active,
.ant-select-dropdown #resources_list + .rc-virtual-list .ant-select-item-option:hover {
    background-color: transparent;
}

.finishActivity {
    background-color: #ececec;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.finishActivity .resourcesActivity span {
    background-color: #e0f3f9;
    padding: 5px 10px;
    border-radius: 8px;
    margin-top: 8px;
    display: inline-block;
    margin-left: 8px;
    border: 1px solid lightblue;
}

.finishActivity .resourcesActivity .ant-tag.ant-tag-has-color.simpleTag {
    background-color: #d3e5d3 !important;
    border: 1px solid lightgreen !important;
    color: #000 !important;
    height: 30px;
    line-height: 18px;
} 

.ratesActivity {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 10px;
    gap: 10px;
}

.ratesActivity > div {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: calc(50% - 10px);
}

.ratesActivity > div > div {
    background-color: aliceblue;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #d2d2d2;
}

.finishActivity .actionBtns {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    grid-gap: 10px;
    gap: 10px;
}

.finishActivity .actionBtns .iconCircle {
    background-color: #8c8c8c;
    border-radius: 50% !important;
    padding-inline: 6px;
    height: 30px;
}

.finishActivity .actionBtns .iconCircle.editBtn {
    background-color: #5675ae;
}

.finishActivity .actionBtns .iconCircle.deleteBtn {
    background-color: #ae565e;
}

.finishActivity .actionBtns .iconCircle svg {
    font-size: 18px;
}
.personalData {
    background-color: #f5f5ff;
    border-radius: 10px;
    padding: 20px;
    margin-top: 58px;
    border: 1px solid lavender;
}

.personalData p, .personalData b {
    font-size: 14px;
}

.ant-row > .activityData:nth-child(1n+3) {
    margin-top: 20px;
}

.activityData {
    background-color: #f5f5ff;
    padding: 10px 20px;
    border-radius: 10px;
    border: 1px solid lavender;
}

.aditionalServices {
    margin: 15px 0;
}

.aditionalServices .ant-collapse {
    border: 1px solid #eaeaea;
}

.aditionalServices .ant-collapse-header {
    justify-content: center;
}

.aditionalServices .ant-collapse-header .ant-collapse-header-text {
    margin: 0 !important;
    flex: unset !important;
}

.servicesList {
    flex: 1 1 auto;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    grid-gap: 25px;
    gap: 25px;
    margin-top: 30px;
}

.servicesList .serviceItem {
    background-color: lavender;
    padding: 10px 30px 10px 15px;
    border-radius: 8px;
    position: relative;
    max-width: 200px;
}

.servicesList .serviceItem .actionBtns {
    position: absolute;
    top: 0;
    right: -10px;
    display: flex;
    flex-direction: column;
    bottom: 0;
    justify-content: center;
    grid-gap: 5px;
    gap: 5px;
}

.servicesList .serviceItem .actionBtns .iconCircle {
    background-color: #8c8c8c;
    border-radius: 50% !important;
    padding-inline: 6px;
    height: 26px;
}

.servicesList .serviceItem .actionBtns .iconCircle.editBtn {
    background-color: #5675ae;
}

.servicesList .serviceItem .actionBtns .iconCircle.deleteBtn {
    background-color: #ae565e;
}

.servicesList .serviceItem .actionBtns .iconCircle svg {
    font-size: 14px;
}

.priceData {
    background-color: #fbf7f2;
    padding: 20px 20px 60px;
    border-radius: 10px;
    border: 1px solid antiquewhite;
}

.priceData .priceTotal,
.priceData .priceTotal b {
    font-size: 16px;
}

.priceData .priceTotal span {
    font-size: 20px;
}

.priceData .priceTotal span.changed {
    color: red !important;
    text-decoration: line-through;
}

.paymentData {
    background-color: #f5f5ff;
    padding: 20px;
    border: 1px solid lavender;
}

.listNotes {
    margin-top: 30px;
}

.listNotes .noteItem {
    background-color: #f5f5ff;
    padding: 20px;
    border: 1px solid lavender;
    border-radius: 10px;
    margin-bottom: 10px;
}

.listNotes .noteItem .iconCircle {
    background-color: #8c8c8c;
    border-radius: 50% !important;
    padding-inline: 7px;
    height: 30px;
}

.listNotes .noteItem .iconCircle.deleteBtn {
    background-color: #ae565e;
}

.listNotes .noteItem .iconCircle svg {
    font-size: 16px;
}
.rbc-event.rbc-event-allday {
    height: 50px;
    margin-top: -19px;
    border-radius: 0;
    /*background-color: #ff2a2c99;*/
    background-color: #87d068b5;
    pointer-events: none;
}

.bookingForm .ant-form-item {
    margin-bottom: 8px;
}

.bookingForm .ant-form-item-label {
    padding-bottom: 4px;
}

.selectedDates .ant-select-selection-overflow {
    justify-content: center;
    margin-top: 18px;
}

.selectedDates .ant-select-selection-overflow .ant-select-selection-overflow-item {
    margin-inline: 3px;
    margin-bottom: 5px;
}

.selectedDates .ant-select-selection-overflow .ant-select-selection-overflow-item .ant-select-selection-item {
    border-bottom-color: #b7eb8f;
    background: #f6ffed;
}

.calendarCol {
    position: relative;
    padding: 20px;
}

.calendarCol .calendarOverlay {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #00000017;
    z-index: 5;
    border-radius: 8px;
}

.stepsForm .ant-steps-item-title {
    font-size: 14px;
    line-height: 18px;
}

.stepsForm .ant-steps-item-tail {
    top: 2px !important;
}

.stepsForm .ant-steps-icon svg {
    font-size: 22px;
}

.stepsForm .ant-steps-item-active .ant-steps-icon svg {
    fill: var(--primary-color);
}

.stepsForm .ant-steps-item-finish +
.ant-steps-item-active .ant-steps-icon svg {
    fill: #000;
}

.stepsForm .ant-steps-item-finish .ant-steps-icon svg {
    fill: var(--primary-color);
    opacity: .5;
}

.stepsForm .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: var(--primary-color);
    opacity: .5;
}

.dateTabs .ant-tabs-nav-wrap {
    /*justify-content: center;*/
}
.toPrintBooking * {
  font-family: 'Barlow';
}

.bono-footer p, .bono-footer span {
  font-size: 11px;
  font-weight: 500;
}

.bono-footer small {
  font-size: 10px;
  color: gray;
}

.bono-footer span.marker {
  color: #06c;
}

.bono-footer p strong {
  font-size: 11px;
  font-weight: 600;
}

.bono-footer ul {
  margin-top: 1rem;
}
.bookingModalRow .bookingTitle {
    font-size: 24px;
}

.bookingModalRow .ant-form-item {
    margin-bottom: 0;
}

.bookingModalRow .ant-form-item label {
    font-weight: bold;
}

.bookingModalRow .personalDataForm textarea {
    border-radius: 0;
    border-bottom: 1px solid #d0d0d0;
}

.bookingModalRow .personalDataForm textarea:focus {
    border-color: var(--primary-color);
}

.bookingModalRow .personalDataForm textarea[disabled] {
    border-color: transparent;
}

.bookingModalRow .bookingInfo,
.bookingModalRow .personalDataInfo {
    background-color: #f5f5ff;
    border: 1px solid lavender;
    padding: 20px;
    border-radius: 10px;
}

.bookingModalRow .infoHeader {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #d6d6dc7d;
}

.bookingModalRow .infoHeader h4 {
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 0;
}

.bookingModalRow .bookingInfo .ant-input[disabled],
.bookingModalRow .personalDataInfo .ant-input[disabled] {
    color: #000000e0;
    cursor: default;
}

.bookingModalRow .paymentInfo {
    padding: 20px;
    border-radius: 10px;
    border: 1px solid;
}

.bookingModalRow .paymentInfo.parcial {
    background: #fff7e6;
    border-color: #ffd591;
}

.bookingModalRow .paymentInfo.unpaid {
    background: #fff1f0;
    border-color: #ffa39e;
}

.bookingModalRow .paymentInfo.paid {
    background: #f6ffed;
    border-color: #b7eb8f;
}

.bookingModalRow .paymentInfo.return {
    background: #e6f4ff;
    border-color: #91caff;
}

.bookingModalRow .paymentInfo.canceled {
    background: #e6e6e6;
}

.bookingModalRow .paymentInfo.bloqued {
    background: #fffcfa;
    border-color: #f5b798;
}

.bookingModalRow .paymentInfo p,
.bookingModalRow .paymentInfo b,
.bookingModalRow .paymentInfo span {
    font-size: 14px;
}

.bookingModalRow .paymentInfo p.pending {
    font-size: 16px;
    /* font-weight: bold; */
}

.bookingModalRow .paymentInfo p.pending span {
    font-weight: 500;
    font-size: 20px;
}

.bookingModalRow .paymentInfo p.unpaid span {
    color: #cf1322 !important;
}

.bookingModalRow .paymentInfo p.parcial span {
    color: #d46b08 !important;
}

.bookingModalRow .paymentInfo p.paid span {
    color: #389e0d !important;
}

.bookingModalRow .paymentInfo p.return span {
    color: #0958d9 !important;
}

.bookingModalRow .activitiesList {
    margin-top: 20px;
}

.bookingModalRow .activitiesList .activityItem {
    background-color: #f5f5ff;
    border: 1px solid lavender;
    padding: 20px;
    border-radius: 10px;
}

.bookingModalRow .activitiesList .activityItem:not(:first-child) {
    margin-top: 20px;
}

.bookingModalRow .activitiesList .activityItemCol {
    background-color: #f5f5ff;
}

.bookingModalRow .activitiesList .activityItemCol:not(:first-child) {
    margin-top: 20px;
}

.bookingModalRow .activitiesList .activityItemCol h4 b {
    font-size: 1.5rem !important;
}

.bookingModalRow .activitiesList .activityItemCol .activityItemColDetail > .ant-col > p.title {
    margin-bottom: 4px !important;
}

.bookingModalRow .activitiesList .activityItem p,
.bookingModalRow .activitiesList .activityItem b {
    font-size: 14px;
}

.totalBooking {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    min-height: 200px;
}

.totalBooking h3 {
    font-size: 1.9rem;
    text-transform: uppercase;
}

.totalBooking h3 b {
    font-size: 2.4rem;
}

.totalBooking .totalPrices h4 {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.totalBooking .totalPrices h4 span {
    font-size: 1.5rem;
}

.bookingModalRow button.ant-btn.iconCircle {
    border-radius: 50% !important;
    padding-inline: 6px;
    height: 30px;
}

.bookingModalRow button.ant-btn.iconCircle svg {
    font-size: 15px;
}

.bookingModalRow .activityActions > button {
    margin-right: -50px;
}

.bookingModalRow .activityActions > button:not(:first-child) {
    margin-top: 5px;
}

.paymentListModal .paymentItem {
    padding: 10px;
    border-radius: 10px;
    border: 1px solid;
}

.paymentListModal .paymentItem.paid {
    background: #f6ffed;
    border-color: #b7eb8f;
}

.paymentListModal .paymentItem.return {
    background: #fff7e6;
    border-color: #ffd591;
}

.servicesList {
    flex: 1 1 auto;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    grid-gap: 25px;
    gap: 25px;
    flex-wrap: wrap;
}

.servicesList .serviceItem {
    background-color: lavender;
    padding: 10px 30px 10px 15px;
    border-radius: 8px;
    position: relative;
    max-width: 200px;
}

.servicesList .serviceItem .actionBtns {
    position: absolute;
    top: 0;
    right: -10px;
    display: flex;
    flex-direction: column;
    bottom: 0;
    justify-content: center;
    grid-gap: 5px;
    gap: 5px;
}

.servicesList .serviceItem .actionBtns .iconCircle {
    background-color: #8c8c8c;
    border-radius: 50% !important;
    padding-inline: 6px;
    height: 30px;
}

.servicesList .serviceItem .actionBtns .iconCircle.editBtn {
    background-color: #5675ae;
}

.servicesList .serviceItem .actionBtns .iconCircle.deleteBtn {
    background-color: #ae565e;
}

.servicesList .serviceItem .actionBtns .iconCircle svg {
    font-size: 14px;
}

form#services {
    padding: 20px;
    border-radius: 10px;
    border: 1px solid transparent;
}

form#services.modeEdit {
    background: #fff7e6;
    border-color: #ffd591 !important;
}

.historicBookingList {
    margin-top: 20px;
}

.historicBookingList .historicBookingItem {
    background-color: #f5f5ff;
    border: 1px solid lavender;
    padding: 10px 20px;
    border-radius: 10px;
}

.iconPromotionAct {
    position: absolute;
    top: -17px;
    right: -21px;
    background-color: var(--primary-color);
    border-radius: 50%;
    padding: 2px;
    fill: #FFF !important;
}
.mainTable .ant-pagination-options .ant-select-dropdown .rc-virtual-list-holder-inner > .ant-select-item-option:last-child {
    position: relative;
}

.mainTable .ant-pagination-options .ant-select-dropdown .rc-virtual-list-holder-inner > .ant-select-item-option:last-child .ant-select-item-option-content {
    color: transparent;
}

.mainTable .ant-pagination-options .ant-select-dropdown .rc-virtual-list-holder-inner > .ant-select-item-option:last-child::before {
    content: "TODOS";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
}

.mainTable .ant-table-column-sorters .ant-table-column-sorter .ant-table-column-sorter-inner .anticon.active svg {
    fill: var(--primary-color);
}

.mainTable .ant-table-filter-column .ant-table-filter-trigger.active svg {
    fill: var(--primary-color);
}

.mainTable .ant-tag:before {
    content: "";
    padding-inline: 6px;
    font-size: 9px;
    border-radius: 50%;
    margin-right: 5px;
    opacity: .8;
}

.mainTable .ant-tag.bloqued:before, .mainTable .ant-tag.simple:before {
    display: none;
}

.mainTable .ant-tag {
    color: #515151 !important;
}

.mainTable .ant-tag:before {
    background-color: #515151 !important;
}

.mainTable .ant-tag.ant-tag-green {
    color: #389e0d !important;
}

.mainTable .ant-tag.ant-tag-green:before {
    background-color: #389e0d !important;
}

.mainTable .ant-tag.ant-tag-orange {
    color: #d46b08 !important;
}

.mainTable .ant-tag.ant-tag-orange:before {
    background-color: #d46b08 !important;
}

.mainTable .ant-tag.ant-tag-red {
    color: #cf1322 !important;
}

.mainTable .ant-tag.ant-tag-red:before {
    background-color: #cf1322 !important;
}

.mainTable .ant-tag.ant-tag-blue {
    color: #0958d9 !important;
}

.mainTable .ant-tag.ant-tag-blue:before {
    background-color: #0958d9 !important;
}

.mainTable .ant-tag.ant-tag-has-color.simpleTag {
    color: #FFF !important;
}
.tablaSimple_Card__2MifC {
  padding: 5px;
}

th {
  background-color: #ffffff !important;
}
tr:nth-child(odd) {
  background-color: #ddd8d82d;
}
thead {
  border: 1px solid green !important;
}

.tablaSimple_backgroundR__1jjoL {
  background-color: #cf1414 !important;
}

.tablaSimple_backgroundB__1SKZS {
  background-color: #f0d8ff;
}

.tablaSimple_backgroundC__1lcx7 {
  background-color: #d7f2fb;
}

/* Dots */

.tablaSimple_dotNoPagado__11-20 {
  height: 9px;
  width: 9px;
  margin-right: 3px;
  background-color: rgba(255, 0, 0, 0.911);
  border-radius: 50%;
  display: inline-block;
}

.tablaSimple_dotParcial__3ufuV {
  height: 9px;
  width: 9px;
  margin-right: 3px;
  background-color: rgba(255, 123, 0, 0.918);
  border-radius: 50%;
  display: inline-block;
}

.tablaSimple_dotActive__QHwb4 {
  height: 9px;
  width: 9px;
  margin-right: 3px;
  background-color: rgba(0, 128, 0, 0.932);
  border-radius: 50%;
  display: inline-block;
}

.tablaSimple_paginationContainer__1aFXn {
  display: flex;
  justify-content: flex-end;
  margin-top: 1%;
}

tr {
  height: 2px;

}

.tablaSimple_trContainer__20fYz {
  height: 80px;
}

.tablaSimple_box1__2ku4V {
  position: relative;
  top: 41px;
  background-color: #58db8c69;
  height: 60px;
}

.tablaSimple_plazasContainer__1PTBN {
  display: flex;
}

.tablaSimple_gg__J9wcm {
  color: white;
}
.tablaSimple_box2__1tBXd {
  background-color: #e8c1f6;
}

.tablaSimple_yesCheck__2gtYy {
  color: rgba(0, 128, 0, 0.932);
  font-size: 15px;
  position: relative;
  left: 45px;
}

.tablaSimple_noCheck__1WPd7 {
  color: rgba(255, 0, 0, 0.911);
  font-size: 17px;
  position: relative;
  left: 47px;
}

.tablaSimple_checksAndSearch__448kO {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 10px;
}

.tablaSimple_checkBoxContainer__1Pn9w {
  position: relative;
  top: 3px;
}

.tablaSimple_searchBarInput__CRvVv {
  border: none;
  border-bottom: solid 1.4px rgb(106, 104, 104);
  width: 200px;
  outline: none;
  font-size: 14px;
  padding-left: 4px;
  position: relative;
}

.tablaSimple_searchBarInput__CRvVv:active {
  border: none;
  outline: none;
}

.tablaSimple_checkbox__3rpyr label {
  color: rgb(68, 68, 68);
  position: relative;
  top: -2px;
  font-weight: 400;
}

/* pagination */
.tablaSimple_selectContainer__1gN7E {
  display: flex;
  justify-content: flex-end;
  width: 99%;
}
.tablaSimple_paginationSelect__30Xgh {
  background-color: #ffffff;
  width: 60px;
  border-radius: 6px;
  padding: 2px;
  outline: none;
  border: solid 0.1px grey;
}

.reservas_calendarioContent__jqsic {
  height: 550px;
}

/* Checkbox*/

.reservas_checkBoxContainer__CZpfi {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

/* Search Bar */

.reservas_searchBarContainer__1FFSV {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  /* padding: 1.5% 0% 1.5% 0%; */
}

.reservas_searchBarContent__1QUjY {
  display: flex;
}

/* Anotación button */

.reservas_anotacionesContainer__3wLbH {
  padding: 4% 1% 1% 1%;
  margin: 0 auto;
  display: flex;
  width: 80%;
  justify-content: space-between;
}

.reservas_anotacionesBox__1zLmx {
  display: flex;
  width: 100%;
}

.reservas_anotacionesBtn__2jSU2 {
  height: 50px;
}

.reservas_dateRange__3JBtU {
  display: flex;
}




.reservas_rangesContainer__iB8Nc {
  width: 40%;
}

.reservas_canalContent__20D7H {
  padding-left: 2%;
  width: 25%;
}

/* Tablas */

.reservas_tablasContainer__3do4W {
  width: 80%;
  margin: 0 auto;
}

.reasignResourceForm h6 span {
    font-size: 16px;
}

.reasignResourceForm .ant-form-item-label label {
    font-weight: 400;
}

.reasignResourceForm #oldResource {
    color: #000;
    font-size: 12px;
}
.schedulerTable {
    scrollbar-width: thin;
    scrollbar-color: #ffd0d0 #e4e4e4;
}

.syncScrollsTop {
    margin-bottom: 8px;
    scrollbar-width: thin;
    scrollbar-color: #ffd0d0 #e4e4e4;
}
.syncScrollsTable {
    padding-bottom: 8px;
    scrollbar-width: thin;
    scrollbar-color: #ffd0d0 #e4e4e4;
}

.schedulerTable.emptyschedulerTable {
    padding-bottom: 0;
}

.schedulerTable.emptyschedulerTable table {
    min-height: 260px;
}

.schedulerTable.emptyschedulerTable .ant-empty-description span {
    color: #c6c6c6 !important;
    font-weight: normal;
}

.schedulerTable .ant-table-tbody tr > td:first-of-type {
    min-width: 140px !important;
    max-width: 140px !important;
    display: table-cell;
    padding: 21px 20px !important;
    font-weight: bold;
}

.schedulerTable .ant-table-tbody tr > td:first-of-type .resourceOverbooking {
    font-weight: bold;
    color: red;
}

.schedulerTable .ant-table-tbody tr > td:first-of-type .emptyResource {
    font-weight: normal;
    opacity: .5;
}

.schedulerTable .ant-table-tbody tr > td:first-of-type .resourceOverbooking svg {
    position: absolute;
    background-color: #FFF;
    border: 1px solid;
    border-radius: 50%;
    padding: 2px;
    fill: red;
    left: 0px;
}

.schedulerTable .ant-table-tbody tr > td:nth-child(4n+1),
.schedulerTable .ant-table-thead tr:nth-of-type(2) > th:nth-child(4n+4),
.schedulerTable .ant-table-thead tr:nth-of-type(1) > th {
    border-right-color: #a1a1a1 !important;
}

.schedulerTable .ant-table-tbody > tr > td {
    border-top-color: #a1a1a1 !important;
}

.schedulerTable .ant-table-thead > tr:first-child > th:first-of-type,
.schedulerTable .ant-table-thead > tr:nth-of-type(2) > th {
    border-bottom-color: #a1a1a1 !important;
}

.schedulerTable .ant-table-thead tr:nth-of-type(2) > th {
    min-width: 65px;
}

.schedulerTable .ant-table-tbody .ant-table-cell.minute-cell {
    padding: 30px 16px !important;
}

.schedulerTable .ant-table-cell.minute-cell span,
.schedulerTable .ant-table-cell.minute-cell > div {
    position: absolute;
    left: -6px;
    top: 6px;
    font-weight: normal;
    color: #b3b3b3 !important;
    font-size: 10px;
    background-color: #FFF;
    height: 50px;
}

.schedulerTable .ant-table-cell.minute-cell > div.multiEvent {
    left: 0;
    top: unset;
}

.schedulerTable .ant-table-thead .ant-table-cell.minute-cell span {
    height: 14px;
}

.schedulerTable .ant-table-cell.minute-cell span.event-cell,
.schedulerTable .ant-table-cell.minute-cell .schedulerEvent {
    left: 0;
    top: unset;
    background-color: var(--primary-color);
    padding: 5px 4px;
    border-radius: 8px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    color: #FFF !important;
    z-index: 1;
    font-size: 12px;
    min-width: 200%;
    display: grid;
    place-content: center;
    border: 1px solid #FFF;
}

.schedulerTable .ant-table-cell.minute-cell .schedulerEvent {
    border: 2px solid #FFF;
    box-shadow: 2px 2px 3px #00000038;
}

.schedulerTable .ant-table-cell.minute-cell .schedulerEvent.overbooking {
    border: 2px solid #000 !important;
}

.schedulerTable .ant-table-cell.minute-cell .schedulerEvent.overbooking:first-child {
    border-bottom-width: 1px !important;
}

.schedulerTable .ant-table-cell.minute-cell > .schedulerEvent.overbooking:not(:first-child) {
    border-top-width: 1px !important;
}

.schedulerTable .ant-table-cell.minute-cell .schedulerEvent.pax {
    padding-right: 20px;
}

.schedulerTable .ant-table-cell.minute-cell .schedulerEvent > div {
    display: flex;
}

.schedulerTable .ant-table-cell.minute-cell .schedulerEvent .paxValues {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    justify-content: center;
    color: #FFF;
    background: #dd3e40;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    padding-inline: 4px;
    font-size: 11px;
}

.schedulerTable .ant-table-cell.minute-cell .schedulerEvent .paxValues.arrived {
    background: #457531;
}

.schedulerTable .ant-table-cell.minute-cell .schedulerEvent .paxValues.checking {
    background: #ae8b55;
}

/* .schedulerTable .ant-table-cell.minute-cell .schedulerEvent .eventStatus {
    position: absolute;
    top: -12px;
    left: 6px;
}

.schedulerTable .ant-table-cell.minute-cell .schedulerEvent .eventStatus a:not(:first-child) {
    margin-left: 3px;
} */

.schedulerTable .ant-table-cell.minute-cell .schedulerEvent .badge-event {
    position: absolute;
    left: 4px;
    width: 6px;
    min-width: 6px;
    height: 6px;
    background-color: var(--primary-color);
    border-radius: 100%;
    box-shadow: 0 0 0 1px #fff;
}

.schedulerTable .ant-table-cell.minute-cell .schedulerEvent .badge-event.badge-overbooking {
    top: 5px;
    background-color: #000;
}

.schedulerTable .ant-table-cell.minute-cell .schedulerEvent .badge-event.badge-price {
    top: 16px;
    background-color: #ffd666;
}

.schedulerTable .ant-table-cell.minute-cell .schedulerEvent .badge-event.badge-vip {
    top: 28px;
    background-color: #fff;
}

.schedulerTable .ant-table-cell.minute-cell .schedulerEvent .badge-event.badge-notes {
    bottom: 5px;
    background-color: #91caff;
}

.legendSchedulerTable {
    display: flex;
    grid-gap: 12px;
    gap: 12px;
    margin-bottom: 16px;
    margin-left: 10px;
    height: 16px;
    padding: 18px 0;
}

.legendSchedulerTable p {
    font-size: 10px;
    display: flex;
    align-items: center;
    grid-gap: 3px;
    gap: 3px;
}

.legendSchedulerTable span.badge-event {
    display: block;
    width: 10px;
    min-width: 10px;
    height: 10px;
    background-color: var(--primary-color);
    border-radius: 100%;
}

.legendSchedulerTable span.badge-event.badge-overbooking {
    background-color: #000;
}

.legendSchedulerTable span.badge-event.badge-price {
    background-color: #ffd666;
}

.legendSchedulerTable span.badge-event.badge-notes {
    background-color: #91caff;
}

.legendSchedulerTable span.badge-event.badge-vip {
    background-color: #fff;
    box-shadow: 0px 0px 0px 1px #0000002e;
}
.emptyTable table {
    min-height: 600px;
}

.emptyTable table .ant-empty-description span {
    color: #aaa !important;
}

.mainTable.emptyMainTable .ant-empty-description span {
    color: #c6c6c6 !important;
    font-weight: normal;
}

.mainTable.emptyMainTable table {
    min-height: 260px;
}

.borded-btn {
    background-color: var(--primary-color);
    padding: 5px 12px !important;
    height: auto;
    border-radius: 40px !important;
}
.borded-btn:hover {
    background-color: var(--primary-color-dark) !important;
}
.borded-btn span, .borded-btn p {
    color: #FFF !important;
}

.borded-btn span {
    margin-right: 5px;
    font-size: 20px;
    line-height: 12px;
}
.borded-btn.circle {
    padding: 7px !important;
}

.schedulerComp {
    margin-top: 20px;
    display: flex;
}

.schedulerComp > div:last-of-type {
    width: 100%;
}

.schedulerComp > div > div:first-child {
    display: none !important;
}

.schedulerComp .rs__outer_table > div {
    overflow-x: hidden;
}

/* .schedulerComp .rs__outer_table > div > div:first-child .css-fpqwey {
    grid-template-columns: 60px;
}

.schedulerComp .rs__outer_table > div > div:first-child .css-fpqwey > span:not(.rs__header) {
    display: none !important;
} */

.schedulerComp .rs__outer_table > div > div:first-child .css-fpqwey > .rs__cell:first-child,
.schedulerComp .rs__outer_table > div > div:first-child .css-fpqwey > .rs__cell:nth-child(2) {
    display: none !important;
}

.schedulerComp .rs__outer_table > div > div:not(:first-child) .css-fpqwey > .rs__cell:first-child,
.schedulerComp .rs__outer_table > div > div .css-fpqwey > .rs__header,
.schedulerComp .rs__outer_table > div > div:not(:first-child) .css-fpqwey > .rs__cell .css-xrjn1 > div:first-child {
    display: none !important;
}

.schedulerComp .rs__outer_table > div > div:not(:first-child) .css-fpqwey > .rs__cell .css-xrjn1 > div:last-of-type {
    width: calc(100% + 2px);
    margin-left: -2px;
}

.schedulerComp .rs__outer_table > div > div .css-fpqwey > .rs__cell .css-xrjn1 > div:last-of-type {
    border-color: #aa1815;
}

.schedulerComp .rs__outer_table > div > div .css-fpqwey > .rs__cell .css-xrjn1 > div:first-of-type {
    background: #aa1815;
}

/* .schedulerComp .rs__outer_table > div > div .css-fpqwey > .rs__cell .css-xrjn1 {
    margin-top: -9px;
} */

.schedulerComp .rs__outer_table > div > div .css-fpqwey {
    grid-template-columns: 1fr !important;
}

.schedulerComp .rs__outer_table > div > div .css-fpqwey {
    grid-template-columns: 60px repeat(1, 1fr);
}

.schedulerComp .rs__outer_table > div > div .css-fpqwey .rs__cell {
    height: 60px !important;
}

.schedulerComp.half .rs__outer_table > div > div .css-fpqwey .rs__cell .rs__event__item {
    margin-top: -7px;
}

.schedulerComp.full .rs__outer_table > div > div .css-fpqwey .rs__cell .rs__event__item {
    margin-top: -2px;
}

.schedulerComp .rs__outer_table > div > div .css-fpqwey .rs__cell .rs__event__item h6,
.schedulerComp .rs__outer_table > div > div .css-fpqwey .rs__cell .rs__event__item p {
    color: #FFF;
}

.schedulerComp .rs__outer_table > div > div .css-fpqwey .rs__cell .rs__event__item {
    width: 100% !important;
}

.schedulerComp .rs__outer_table > div > div .css-fpqwey .rs__cell .rs__event__item .MuiButtonBase-root > div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.schedulerComp .rs__outer_table > div > div .css-fpqwey > .rs__cell.rs__time span {
    font-size: 12px;
    letter-spacing: 0;
    font-family: unset;
}

.schedulerComp .schedulerEvent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    max-width: 150px;
    margin: auto;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
}

.schedulerComp .schedulerEvent.overbooking {
    margin: unset;
}

.schedulerComp .schedulerEvent .eventStatus {
    position: absolute;
    top: -12px;
    left: 10px;
}

/* .schedulerComp .schedulerEvent.overbooking a {
    position: absolute;
    top: -10px;
    left: 10px;
} */

.schedulerComp .schedulerEvent .eventStatus a:not(:first-child) {
    margin-left: 3px;
}

.schedulerComp .schedulerEvent a svg {
    fill: #ff4d4f;
    font-size: 20px;
    background-color: #FFF;
    border: 1px solid #00000090;
    padding: 2px;
    border-radius: 50%;
}

.schedulerComp .schedulerEvent div {
    color: #FFF;
}

.schedulerComp .css-fpqwey .rs__cell .MuiButtonBase-root {
    pointer-events: none;
}

.schedulerComp .rs__outer_table > div > div .css-fpqwey .css-xrjn1 {
    z-index: 1;
}

.schedulerComp .rs__outer_table > div {
    overflow-x: auto;
}

.schedulerComp .rs__outer_table > div > div {
    min-width: 300px;
}

.mainTable .arrived svg,
.mainTable .annotations svg {
    font-size: unset;
    color: unset;
}

.buscadorReservas_tablaContainer__2yjF1 {
  width: 80%;
  margin: 0 auto;
}

.buscadorReservas_activityCardContainer__3PYk8 {
  width: 20%;
  margin-top: 1%;
}

.buscadorReservas_tableFooter__bPWWc {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.buscadorReservas_crearReservaBtn__1cdyh {
  height: 50px;
  margin-top: 1%;
}

.buscadorReservas_searchBarContent__1PE2Z {
  display: flex;
  height: 30px;
}

.buscadorReservas_rangesContainer__niIhp {
  width: 90%;

  padding: 12px;
}

.buscadorReservas_btnEntradaSearch__14Vwk,
.buscadorReservas_btnReservaSearch__Bl8vW {
  padding-left: 7px;
  padding-right: 7px;
  height: 23px;
  border: none;
  background-color: rgba(58, 57, 57, 0.616);
  position: relative;
  left: 12px;
  top: 2px;
}

.buscadorReservas_searchIcon__3mAz_ {
  color: white;
  font-size: 8.5px;
  position: relative;
  top: -1px;
}

.buscadorReservas_checkbox__10DN0 label {
  font-size: small;
  font-weight: 400;
}

/* //date search inputs */

.buscadorReservas_dateInput__Iuro4 {
  border: none !important;
  border-bottom: 1px solid grey !important;
  border-radius: 0px !important;
  padding: 0 !important;
  width: 140px !important;
  font-size: 13px !important;
  padding-left: 4px !important;
  color: grey !important;
}

.buscadorReservas_labelsDateRange__1a3hg {
  position: relative;
  top: 3px;
  width: 40%;
  font-size: 13px;
  color: rgb(63, 63, 63) !important;
}

.buscadorReservas_secondLabel__1JfP4 {
  width: 30%;
  padding-left: 10%;
}

.buscadorReservas_date__TC2bI {
  display: flex;
}

.buscadorReservas_canalSelect__2-kb3 {
  width: 100px;
  padding: 3px;
  font-size: 13px;
  color: rgb(63, 63, 63) !important;
}

.iconCircle {
    border-radius: 50% !important;
    height: 30px;
    width: 30px;
}

.iconCircle svg {
    font-size: 16px !important;
}

.iconCircle.icon-24 svg {
    font-size: 24px !important;
}

.formSerachBooking {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
}

.mainTable .ant-table .ant-table-tbody .ant-table-row.owner-b2b {
    background-color: var(--primary-color-opacity-10);
}

.mainTable .ant-table .ant-table-tbody .ant-table-row.owner-b2b:hover > td {
    background-color: var(--primary-color-opacity-15);
}

.mainTable .ant-table .ant-table-tbody tr:nth-child(2n+1).owner-b2b,
.mainTable .ant-table .ant-table-tbody tr:nth-child(2n+1).owner-b2b:hover > td {
    background-color: var(--primary-color-opacity-15);
}

.dark-mode .mainTable .ant-table .ant-table-tbody .ant-table-row.owner-b2b {
    background-color: var(--primary-color-opacity-30);
}

.dark-mode .mainTable .ant-table .ant-table-tbody .ant-table-row.owner-b2b:hover > td {
    background-color: var(--primary-color-opacity-35);
}

.dark-mode .mainTable .ant-table .ant-table-tbody tr:nth-child(2n+1).owner-b2b,
.dark-mode .mainTable .ant-table .ant-table-tbody tr:nth-child(2n+1).owner-b2b:hover > td {
    background-color: var(--primary-color-opacity-35);
}
.mensualProdTable {
    overflow-x: auto;
}

.mensualProdTable table {
    background-color: #fff;
}

.simpleTable.ant-table-wrapper .ant-table-thead > tr > th,
.simpleTable.ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 5px 10px !important;
}

.simpleTable.ant-table-wrapper table {
  border-radius: 0;
}

.simpleTable.ant-table-wrapper .ant-table {
  border-radius: 0;
}

.simpleTable.ant-table-wrapper .ant-table-container {
  border-start-start-radius: 0;
  border-start-end-radius: 0;
}

.simpleTable.ant-table-wrapper .ant-table-tbody > tr:nth-child(2n+1),
.simpleTable.ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover,
.simpleTable.ant-table-wrapper .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background-color: transparent;
}

.simpleTable.colored.ant-table-wrapper .ant-table {
  background-color: #a6daf2;
  font-weight: bold;
}

.simpleTable.colored.ant-table-wrapper .ant-table th {
  background-color: transparent !important;
}

.simpleTable.coloredTwo.ant-table-wrapper .ant-table {
  background-color: #dbf0fa;
}

.simpleTable.noBody.ant-table-wrapper .ant-table-tbody {
  display: none;
}

.simpleTable.invoice.colored.ant-table-wrapper .ant-table {
  background-color: #f2a6a6;
}

.simpleTable.invoice.coloredTwo.ant-table-wrapper .ant-table {
  background-color: #fadbdb;
}
@media print {
  @page {
    size: auto;
    margin: 10mm 0;
  }
}

.header-client * {
  font-size: 15px;
}
[class^='formularioReserva_One'] .error {
    border: 2px solid red;
}

.react-confirm-alert-overlay {
    z-index: 1200;
    background-color: rgba(77, 77, 77, 0.911);
}

.react-confirm-alert-overlay .react-confirm-alert-body {
    /* border: 1px solid gainsboro; */
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
    font-size: 18px;
}

.react-confirm-alert-overlay .react-confirm-alert-body h1 {
    margin-bottom: 15px;
    font-size: 25px;
}

.overlay-confirm-suprimir .react-confirm-alert-body .confirm-btn {
    background-color: #38ba7c;
}

.overlay-confirm-suprimir .react-confirm-alert-body .cancel-btn {
    background-color: #8c8c8c;
}

.overlay-confirm-suprimir .react-confirm-alert-body .close-btn {
    background-color: #c36069;
}

.modal .closeBtn i {
    font-size: 20px;
}

.modal .closeBtn i:hover {
    cursor: pointer;
}

.modal .btn-cancel {
    background-color: #c36069;
    color: #FFF !important;
}

.modal .btn-cancel:hover, .modal .btn-cancel:focus {
    background-color: #ae565e;
    border-color: #ae565e;
}

.modal.infoReserva .modal-header {
    padding-inline: 20px;
}

.modal.infoReserva .modal-header span:first-child {
    font-weight: bold;
    font-size: 20px;
}

.ant-modal-root .ant-modal-mask, .ant-modal-root .ant-modal-wrap {
    z-index: 500;
}

.ant-modal .ant-modal-close {
    position: absolute;
    right: 12px;
    left: unset;
    top: 10px;
}

.ant-modal .ant-modal-close svg {
    font-size: 20px;
}

.ant-select-selector .ant-select-selection-placeholder {
    color: #aaa !important;
}

.ant-select .anticon-loading svg {
    fill: var(--primary-color);
}

.primaryColor {
    color: var(--primary-color) !important;
}

.whiteColor {
    color: #FFF !important;
}

span.red {
    color: red !important;
}

/* BUTTONS */

button.ant-btn.basic-btn {
    background-color: #9a9a9a;
    border-color: #9a9a9a;
    line-height: 20px;
}

button.ant-btn.main-btn {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    line-height: 20px;
}

button.ant-btn.basic-btn.accept-btn {
    background-color: #78ad62;
    border-color: #78ad62;
}

button.ant-btn.main-btn span,
button.ant-btn.basic-btn span {
    color: #fff !important;
}

button.ant-btn.main-btn span svg,
button.ant-btn.basic-btn span svg {
    fill: #fff !important;
    font-size: 14px;
}

button.ant-btn.main-btn.radius-circle {
    border-radius: 50% !important;
}

button.ant-btn.basic-btn:hover {
    background-color: #6a6a6a;
    border-color: #6a6a6a;
}

button.ant-btn.main-btn:hover {
    background-color: var(--primary-color-dark);
    border-color: var(--primary-color-dark);
}

button.ant-btn.basic-btn.accept-btn:hover {
    background-color: #588d42;
    border-color: #588d42;
}

button.ant-btn.basic-btn:disabled,
button.ant-btn.main-btn:disabled {
    opacity: .5;
}

button.ant-btn.main-btn.btn-medium span {
    font-size: 14px;
}

button.rounded-btn {
    height: 40px;
    border-radius: 40px !important;
    padding-inline: 12px;
}

button.rounded-btn p {
    color: #FFF;
}

button.rounded-btn span {
    font-size: 20px;
    line-height: 12px;
    margin-right: 5px;
}

button.ant-btn svg {
    display: block;
}

button.ant-btn.iconCircle {
    padding: 0;
    border: none;
    box-shadow: none;
    background-color: transparent;
}

button.ant-btn.iconCircle:disabled {
    opacity: .5;
}

button.ant-btn.iconCircle span {
    margin-top: 4px;
}

button.ant-btn.iconCircle svg {
    font-size: 24px;
}

button.ant-btn.main-btn.disabledNoClick, button.ant-btn.main-btn.disabledClick {
    opacity: .5;
}

button.ant-btn.main-btn.disabledNoClick {
    pointer-events: none;
}

button.ant-btn.iconCircle {
    background-color: #8c8c8c;
}

button.ant-btn.iconCircle.mainBtn {
    background-color: var(--primary-color);
}

button.ant-btn.iconCircle.editBtn {
    background-color: #5675ae;
}

button.ant-btn.iconCircle.deleteBtn {
    background-color: #ae565e;
}

button.ant-btn.iconCircle.confirmBtn {
    background-color: #8aba79;
}

button.ant-btn.iconCircle.historicBtn {
    background-color: #6ea59d;
}

button.ant-btn.iconCircle.lockBtn {
    background-color: #f5b798;
}

button.ant-btn.iconCircle.lockBtn svg {
    font-size: 18px !important;
}

.validateBtn svg {
    font-size: 18px !important;
}

.button-rounded-main {
    background-color: var(--primary-color);
    padding: 5px 12px !important;
    height: auto;
    border-radius: 40px !important;
}
.button-rounded-main:hover {
    background-color: var(--primary-color-dark) !important;
}
.button-rounded-main span, .button-rounded-main p {
    color: #FFF !important;
}

/* POPCONFIRM */

.ant-popconfirm .ant-popconfirm-buttons .ant-btn-primary {
    background-color: var(--primary-color);
}

.ant-popconfirm .ant-popconfirm-buttons .ant-btn-primary span {
    color: #FFF !important;
}

.ant-popconfirm .ant-popconfirm-buttons > button {
    padding: 0 20px;
}

/* ICONS */

span.anticon.greyColor svg {
    fill: #8c8c8c;
}

span.anticon.white svg {
    fill: #FFF;
}

span.anticon.black svg {
    fill: #000;
}

/* NOTIFICATIONS */

.ant-notification-notice-warning .anticon-exclamation-circle svg {
    fill: #faad14;
    font-size: 22px;
}

.ant-notification-notice-error .anticon-close-circle svg {
    fill: var(--primary-color);
    font-size: 22px;
}

/* TABLE */

.ant-table-wrapper .ant-table-tbody > tr > td {
    padding: 8px 16px !important;
}

.ant-table-filter-dropdown .ant-table-filter-dropdown-btns {
    grid-gap: 10px;
    gap: 10px;
}

.ant-table-filter-dropdown .ant-table-filter-dropdown-btns .ant-btn.ant-btn-primary {
    background-color: var(--primary-color);
}

.ant-table-filter-dropdown .ant-table-filter-dropdown-btns .ant-btn.ant-btn-primary span {
    color: #FFF !important;
}

.ant-table-filter-dropdown .ant-checkbox-checked .ant-checkbox-inner,
.ant-table-filter-dropdown .ant-checkbox-checked:hover .ant-checkbox-inner {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.ant-table-wrapper .ant-table-tbody > tr.ant-table-measure-row > td {
    padding: 1px 0 !important;
}

.ant-table-filter-column .ant-table-filter-trigger.active svg,
.ant-table-column-sorters .ant-table-column-sorter .ant-table-column-sorter-inner .anticon.active svg {
    fill: var(--primary-color);
}

/* TAGS */

.bigCalendar-container .ant-tag:before {
    content: "";
    padding-inline: 6px;
    font-size: 9px;
    border-radius: 50%;
    margin-right: 5px;
    opacity: .8;
}

.ant-tag.ant-tag-green {
    color: #389e0d !important;
}

.bigCalendar-container .ant-tag.ant-tag-green:before {
    background-color: #389e0d !important;
}

.ant-tag.ant-tag-orange {
    color: #d46b08 !important;
}

.bigCalendar-container .ant-tag.ant-tag-orange:before {
    background-color: #d46b08 !important;
}

.ant-tag.ant-tag-red {
    color: #cf1322 !important;
}

.bigCalendar-container .ant-tag.ant-tag-red:before {
    background-color: #cf1322 !important;
}

.ant-tag.ant-tag-blue {
    color: #0958d9 !important;
}

.bigCalendar-container .ant-tag.ant-tag-blue:before {
    background-color: #0958d9 !important;
}

.ant-tag.ant-tag-has-color.simpleTag {
    color: #FFF !important;
}

/* TABS */

.ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--primary-color);
    /* font-weight: bold; */
}

.ant-tabs-nav .ant-tabs-nav-list .ant-tabs-ink-bar {
    background: var(--primary-color);
}

/* TEXT */

span.ant-typography.ant-typography-warning {
    color: #faad14 !important;
}

/* BADGES */

.ant-badge .ant-badge-count {
    background: var(--primary-color);
}

.ant-badge .ant-badge-count span {
    color: #FFF !important;
}

/* LAYOUT */

.ant-layout .ant-layout-sider,
.ant-layout .ant-layout-header,
.ant-menu-dark {
    /* background: #dfdfdf; */
    background: #f0f0f0;
}

.ant-layout .ant-layout-sider-trigger {
    /* background: #b6b6b6; */
    background: #d8d8d8;
}

.ant-layout-header.collapsed {
    padding-left: 130px;
}

.ant-layout-header.uncollapsed {
    padding-left: 250px;
}

.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item {
    margin-inline: 0;
    width: 100%;
    border-radius: 0;
}

.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: var(--primary-color);
}

.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item:active {
    background-color: var(--primary-color) !important;
}

.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item svg {
    font-size: 18px;
}

.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected svg,
.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item:active svg {
    fill: #FFF;
}

.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected span,
.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item:active span {
    color: #FFF !important;
}

.avatarHeader svg {
    font-size: 16px;
}

/* CHECKBOX */

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
    border-color: var(--primary-color);
}

.ant-checkbox-wrapper-checked:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
.ant-checkbox-checked:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
    background-color: var(--primary-color);
}

.ant-checkbox-wrapper span {
    font-weight: 400;
}

/* TIMEPICKER */

.ant-picker-dropdown .ant-picker-ranges {
    justify-content: center;
}

.ant-picker-dropdown .ant-picker-ranges .ant-picker-ok button {
    background-color: var(--primary-color);
}

.ant-picker-dropdown .ant-picker-ranges .ant-picker-ok button span {
    color: #FFF !important;
}

.ant-picker-dropdown .ant-picker-ranges .ant-picker-ok {
    display: none;
}

/* DATAPICKER */

.ant-picker-dropdown .ant-picker-panel .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-dropdown .ant-picker-panel .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: var(--primary-color);
}

.ant-picker-dropdown .ant-picker-panel .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.ant-picker-dropdown .ant-picker-panel .ant-picker-cell-in-view.ant-picker-cell-range-start::before,
.ant-picker-dropdown .ant-picker-panel .ant-picker-cell-in-view.ant-picker-cell-range-end::before {
    background: var(--primary-color-opacity-15) !important;
}

/* MENU */

.ant-menu-dark.ant-menu-submenu > .ant-menu,
.ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline {
    background-color: #f0f0f0;
}

.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
    margin-inline: 0px;
    width: 100%;
    border-radius: 0px;
}

.ant-menu-submenu .anticon > svg {
    font-size: 17px;
}

.ant-menu-submenu-selected .ant-menu-submenu-title,
.ant-menu-submenu-selected .ant-menu-submenu-title:hover {
    background-color: var(--primary-color) !important;
}

.ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-title-content {
    color: #FFF;
}

.ant-menu-submenu-selected .ant-menu-submenu-title svg {
    fill: #FFF;
}

.ant-menu-submenu-selected.ant-menu-submenu-open .ant-menu-submenu-title {
    background-color: transparent !important;
}

.ant-menu-submenu-selected.ant-menu-submenu-open .ant-menu-submenu-title .ant-menu-title-content {
    color: #000;
}

.ant-menu-submenu-selected.ant-menu-submenu-open .ant-menu-submenu-title svg {
    fill: #000;
}

.ant-menu-submenu-selected.ant-menu-submenu-open .ant-menu-submenu-title .ant-menu-submenu-arrow {
    color: #000;
}

.menu-col > span {
    margin-left: 8px;
}

/* B2B */

.swith-mode-btn {
    width: 50px;
    margin: auto;
}

.swith-mode-btn.ant-switch-checked,
.swith-mode-btn.ant-switch-checked:focus,
.swith-mode-btn.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background-color: #b7b76c;
}

.swith-mode-btn,
.swith-mode-btn:focus,
.swith-mode-btn:hover:not(.ant-switch-disabled) {
    background-color: #565a5e;
    border-radius: 100px !important;
}

.ant-switch.swith-mode-btn .ant-switch-inner > span {
    font-size: 6px;
}

.ant-switch.swith-mode-btn .ant-switch-inner .ant-switch-inner-checked {
    -webkit-margin-start: 3px;
            margin-inline-start: 3px;
    -webkit-margin-end: 30px;
            margin-inline-end: 30px;
}

.ant-switch.swith-mode-btn .ant-switch-inner .ant-switch-inner-unchecked {
    margin-top: -22px;
    -webkit-margin-start: 30px;
            margin-inline-start: 30px;
    -webkit-margin-end: 3px;
            margin-inline-end: 3px;
}

/* EMPTY */

.emptyBoxTable .ant-empty-description span {
    color: #a0a0a0 !important;
}

/* ANIMATIONS */

.bounce {
	/* -webkit-animation: bounce 2s; */
	-webkit-animation: bounce 2s;
	        animation: bounce 2s;
	/* -webkit-animation-iteration-count: infinite; */
	-webkit-animation-iteration-count: infinite;
	        animation-iteration-count: infinite;
    /* -webkit-animation: shake 0.8s cubic-bezier(.36,.07,.19,.97) infinite; */
    /* animation: shake 0.8s cubic-bezier(.36,.07,.19,.97) infinite;
    animation-delay: 20s; */
}

.blob.orange {
    background: rgba(140, 140, 140, 1) !important;
    box-shadow: 0 0 0 0 rgba(140, 140, 140, 1);
    -webkit-animation: pulse-orange 2s infinite;
            animation: pulse-orange 2s infinite;
}

.ring {
    -webkit-animation: ring 4s .7s ease-in-out infinite;
            animation: ring 4s .7s ease-in-out infinite;
    -webkit-transform-origin: 50% 4px;
            transform-origin: 50% 4px;
}

@-webkit-keyframes bounce {
	0%,
	25%,
	50%,
	75%,
	100% {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
	40% {
		-webkit-transform: translateY(-8px);
		        transform: translateY(-8px);
	}
	60% {
		-webkit-transform: translateY(-5px);
		        transform: translateY(-5px);
	}
}

@keyframes bounce {
	0%,
	25%,
	50%,
	75%,
	100% {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
	40% {
		-webkit-transform: translateY(-8px);
		        transform: translateY(-8px);
	}
	60% {
		-webkit-transform: translateY(-5px);
		        transform: translateY(-5px);
	}
}

@-webkit-keyframes shake {
    10%, 90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
}

@keyframes shake {
    10%, 90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
}


@-webkit-keyframes pulse-orange {
    0% {
      /* transform: scale(0.95); */
      box-shadow: 0 0 0 0 rgba(140, 140, 140, 0.7);
    }
    
    70% {
      /* transform: scale(1); */
      box-shadow: 0 0 0 10px rgba(140, 140, 140, 0);
    }
    
    100% {
      /* transform: scale(0.95); */
      box-shadow: 0 0 0 0 rgba(140, 140, 140, 0);
    }
}


@keyframes pulse-orange {
    0% {
      /* transform: scale(0.95); */
      box-shadow: 0 0 0 0 rgba(140, 140, 140, 0.7);
    }
    
    70% {
      /* transform: scale(1); */
      box-shadow: 0 0 0 10px rgba(140, 140, 140, 0);
    }
    
    100% {
      /* transform: scale(0.95); */
      box-shadow: 0 0 0 0 rgba(140, 140, 140, 0);
    }
}

@-webkit-keyframes ring {
    0% { -webkit-transform: rotate(0); transform: rotate(0); }
    1% { -webkit-transform: rotate(30deg); transform: rotate(30deg); }
    3% { -webkit-transform: rotate(-28deg); transform: rotate(-28deg); }
    5% { -webkit-transform: rotate(34deg); transform: rotate(34deg); }
    7% { -webkit-transform: rotate(-32deg); transform: rotate(-32deg); }
    9% { -webkit-transform: rotate(30deg); transform: rotate(30deg); }
    11% { -webkit-transform: rotate(-28deg); transform: rotate(-28deg); }
    13% { -webkit-transform: rotate(26deg); transform: rotate(26deg); }
    15% { -webkit-transform: rotate(-24deg); transform: rotate(-24deg); }
    17% { -webkit-transform: rotate(22deg); transform: rotate(22deg); }
    19% { -webkit-transform: rotate(-20deg); transform: rotate(-20deg); }
    21% { -webkit-transform: rotate(18deg); transform: rotate(18deg); }
    23% { -webkit-transform: rotate(-16deg); transform: rotate(-16deg); }
    25% { -webkit-transform: rotate(14deg); transform: rotate(14deg); }
    27% { -webkit-transform: rotate(-12deg); transform: rotate(-12deg); }
    29% { -webkit-transform: rotate(10deg); transform: rotate(10deg); }
    31% { -webkit-transform: rotate(-8deg); transform: rotate(-8deg); }
    33% { -webkit-transform: rotate(6deg); transform: rotate(6deg); }
    35% { -webkit-transform: rotate(-4deg); transform: rotate(-4deg); }
    37% { -webkit-transform: rotate(2deg); transform: rotate(2deg); }
    39% { -webkit-transform: rotate(-1deg); transform: rotate(-1deg); }
    41% { -webkit-transform: rotate(1deg); transform: rotate(1deg); }
    43% { -webkit-transform: rotate(0); transform: rotate(0); }
    100% { -webkit-transform: rotate(0); transform: rotate(0); }
  }

@keyframes ring {
    0% { -webkit-transform: rotate(0); transform: rotate(0); }
    1% { -webkit-transform: rotate(30deg); transform: rotate(30deg); }
    3% { -webkit-transform: rotate(-28deg); transform: rotate(-28deg); }
    5% { -webkit-transform: rotate(34deg); transform: rotate(34deg); }
    7% { -webkit-transform: rotate(-32deg); transform: rotate(-32deg); }
    9% { -webkit-transform: rotate(30deg); transform: rotate(30deg); }
    11% { -webkit-transform: rotate(-28deg); transform: rotate(-28deg); }
    13% { -webkit-transform: rotate(26deg); transform: rotate(26deg); }
    15% { -webkit-transform: rotate(-24deg); transform: rotate(-24deg); }
    17% { -webkit-transform: rotate(22deg); transform: rotate(22deg); }
    19% { -webkit-transform: rotate(-20deg); transform: rotate(-20deg); }
    21% { -webkit-transform: rotate(18deg); transform: rotate(18deg); }
    23% { -webkit-transform: rotate(-16deg); transform: rotate(-16deg); }
    25% { -webkit-transform: rotate(14deg); transform: rotate(14deg); }
    27% { -webkit-transform: rotate(-12deg); transform: rotate(-12deg); }
    29% { -webkit-transform: rotate(10deg); transform: rotate(10deg); }
    31% { -webkit-transform: rotate(-8deg); transform: rotate(-8deg); }
    33% { -webkit-transform: rotate(6deg); transform: rotate(6deg); }
    35% { -webkit-transform: rotate(-4deg); transform: rotate(-4deg); }
    37% { -webkit-transform: rotate(2deg); transform: rotate(2deg); }
    39% { -webkit-transform: rotate(-1deg); transform: rotate(-1deg); }
    41% { -webkit-transform: rotate(1deg); transform: rotate(1deg); }
    43% { -webkit-transform: rotate(0); transform: rotate(0); }
    100% { -webkit-transform: rotate(0); transform: rotate(0); }
  }
