.Card {
  padding: 5px;
}

th {
  background-color: #ffffff !important;
}
tr:nth-child(odd) {
  background-color: #ddd8d82d;
}
thead {
  border: 1px solid green !important;
}

.backgroundR {
  background-color: #cf1414 !important;
}

.backgroundB {
  background-color: #f0d8ff;
}

.backgroundC {
  background-color: #d7f2fb;
}

/* Dots */

.dotNoPagado {
  height: 9px;
  width: 9px;
  margin-right: 3px;
  background-color: rgba(255, 0, 0, 0.911);
  border-radius: 50%;
  display: inline-block;
}

.dotParcial {
  height: 9px;
  width: 9px;
  margin-right: 3px;
  background-color: rgba(255, 123, 0, 0.918);
  border-radius: 50%;
  display: inline-block;
}

.dotActive {
  height: 9px;
  width: 9px;
  margin-right: 3px;
  background-color: rgba(0, 128, 0, 0.932);
  border-radius: 50%;
  display: inline-block;
}

.paginationContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 1%;
}

tr {
  height: 2px;

}

.trContainer {
  height: 80px;
}

.box1 {
  position: relative;
  top: 41px;
  background-color: #58db8c69;
  height: 60px;
}

.plazasContainer {
  display: flex;
}

.gg {
  color: white;
}
.box2 {
  background-color: #e8c1f6;
}

.yesCheck {
  color: rgba(0, 128, 0, 0.932);
  font-size: 15px;
  position: relative;
  left: 45px;
}

.noCheck {
  color: rgba(255, 0, 0, 0.911);
  font-size: 17px;
  position: relative;
  left: 47px;
}

.checksAndSearch {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 10px;
}

.checkBoxContainer {
  position: relative;
  top: 3px;
}

.searchBarInput {
  border: none;
  border-bottom: solid 1.4px rgb(106, 104, 104);
  width: 200px;
  outline: none;
  font-size: 14px;
  padding-left: 4px;
  position: relative;
}

.searchBarInput:active {
  border: none;
  outline: none;
}

.checkbox label {
  color: rgb(68, 68, 68);
  position: relative;
  top: -2px;
  font-weight: 400;
}

/* pagination */
.selectContainer {
  display: flex;
  justify-content: flex-end;
  width: 99%;
}
.paginationSelect {
  background-color: #ffffff;
  width: 60px;
  border-radius: 6px;
  padding: 2px;
  outline: none;
  border: solid 0.1px grey;
}
